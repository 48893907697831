@import '../../../styles/base.scss';

.included-feature {
    &__container {
        @include font--small();
        padding: $space-squish-insert-m;
    }
    &__highlight {
        @include font--small();
        @include font--strong();
    }
}
