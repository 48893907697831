.modal {
    .modal-header {
        text-align: left;
    }
}
.protocol-checkout-warning-modal-content {
    max-width: 615px !important;
    width: 615px !important;

    .protocol-checkout-warning-modal {
        padding: 43px 36px 34px 36px;

        .beta-selector {
            .modal-header {
                text-align: left;
                margin-bottom: 49px;
            }

            .modal-info {
                &__3 {
                    margin-bottom: 82px;
                }
            }
        }
        .beta-selector-accept-button {
            max-width: 70%;
        }
    }
}
