@import '../../../styles/base.scss';

.media-count {
    left: 0;
    top: 0;
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px 0 4px 0;
    color: white;
    padding: $space-xs;
}
