.header-logo {
    &__container {
        display: flex;
        align-items: center;
    }
    &__image {
        width: auto;
        height: 18px;
    }
}
.header-pill {
    font-size: 10px ;
    margin-left: 6px ;
    pointer-events: none ;
}