@import '../../../styles/base.scss';

.divider {
    height: 1px;
    background-color: $border-color;
    flex: 0 0 auto;
    &--black {
        background-color: black;
    }
    &--vertical {
        display: inline-block;
        width: 1px;
        height: 100%;
        background-color: $secondary-accent;
    }
    &--thick {
        height: 2px;
    }
}
