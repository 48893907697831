@import '../../../styles/base.scss';

.std-button {
    @include std-button-states();
    @include font-button();
    @include font--small();
    display: inline-block;
    color: $black-alpha-85;
    border: 1px solid $black-alpha-85;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 4px 16px;
    background: transparent;
    text-transform: uppercase;
    cursor: pointer;

    &:hover:not(:disabled) {
        color: $black-alpha-100;
        border: 1px solid $black-alpha-100;
    }

    &--disabled {
        color: $black-alpha-55;
        border: 1px solid $black-alpha-55;
        cursor: not-allowed;
    }

    // imitating <button/> default behavior for other elements
    &--tag-fallback {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &--primary {
        border: 1px solid transparent;
        background: $sunglow-button;

        &:hover:not(:disabled) {
            border: 1px solid transparent;
            background: $sunglow-button-hover;
        }
    }

    &--danger {
        @include font--strong();
        color: white;
        border: 1px solid transparent;
        background: $rose-madder;

        &:hover:not(:disabled) {
            @include font--strong();
            color: white;
            border: 1px solid transparent;
            background: $rose-hover;
        }
    }

    &--dark {
        border: 1px solid white;
        color: white;
        &:hover:not(:disabled) {
            color: $platinum;
            border: 1px solid $platinum;
        }
    }
    &--black {
        color: white;
        line-height: 14px;                       
        border: 1px solid white;
        background: $dark-black;
        &:hover:not(:disabled) {
            color: $platinum;
            border: 1px solid $platinum;
        }
    }

    &.std-button--round {
        padding: 8px;

        // fix to inline-block alignment/spacing issues
        line-height: 14px;
    }

    // size variants
    &--small {
        @include font--small();
        padding: 2px 16px;
        &.std-button--round {
            padding: 6px;
        }
    }
    &--medium {
        padding: 8px 16px;
        &.std-button--round {
            padding: 12x;
        }
    }
    &--large {
        padding: 12px 16px;
        &.std-button--round {
            padding: 16px;
        }
    }
    &--round {
        border-radius: 100px;

        & > .std-icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: top;

            & > svg.icon-override {
                vertical-align: top;
                color: inherit;
            }
        }
    }
}
