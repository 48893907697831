@import '../../../styles/base.scss';

.std-chip {
    @include text-link();
    @include std-button-states();
    @include std-button__change-color($neutral, $neutral-bg);
    @include font-button();
    @include font--small();

    display: inline-block;
    padding: 2px 12px;
    box-sizing: border-box;
    line-height: 22px;
    border-radius: 100px;
    cursor: pointer;
    .std-icon {
        @include icon-small-quickfix($adjust-align: true);
    }

    &--small {
        padding: 0 12px;
        line-height: 20px;
    }
    
    &--danger {
        @include text-link--color($danger);
        @include std-button__change-color($danger, $danger-bg);
    }
    &--fill-danger {
        background-color: #D23B3D;
        color: #ffffff;
    }
    &--blue {
        @include text-link--color($info);
        @include std-button__change-color($info, $info-bg);
    }
    &--fill-blue {
        background-color: #1DAAD1;
        color: #ffffff;
    }
    &--warn {
        @include text-link--color($accent-contrast);
        @include std-button__change-color($accent-contrast, $accent-bg);
    }
    &--fill-warn {
        background-color: $accent-contrast;
        color: #ffffff;
    }
    &--black {
        @include text-link--color(white);
        @include std-button__change-color(white, black);
    }
    &--grey {
        background-color: #E2E2E2;
        
    }
    &--fill-success {
        background-color: #44A979;
        color: #ffffff;
    }
    &--nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    &--disabled {
        color: $black-alpha-35;
        background: $neutral-bg;
        pointer-events: none;
        &:hover {
            color: $black-alpha-35;
            background: $black-alpha-10;
        }
    }
}
