@import '../../../styles/base.scss';

.std-link {
    @include text-link(inherit);
    border-bottom: 1px solid black;
    line-height: 14px;

    &--nolink {
        border-bottom: 1px solid transparent;
        cursor: revert;
    }

    &--nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
