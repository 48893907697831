.overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;

    &--transparent {
        background: rgba(0, 0, 0, 0.7);
    }
}
