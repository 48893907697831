@import '../../../styles/base.scss';

.select-object-modal {
    &__content {
        overflow: auto;
        height: 400px;
        box-sizing: border-box;
        padding: $space-squish-insert-l;
    }
}
