@import '../../../styles/base.scss';

.plan-select {
    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        background: $neutral-bg;
        min-height: 100%;
    }

    &__plans {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        box-sizing: border-box;
        max-width: 100%;
    }

    &__plans-content {
        display: flex;
        align-items: flex-end;
    }

    &__heading {
        @include flex-between();
        padding: $space-xl 0;
    }

    &__header-text {
        @include font-header();
        text-align: center;
        margin: 0 100px;

        @include breakpoint(phone) {
            margin: 0;
            margin-right: $space-m;
        }
    }

    &__options-table {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        position: relative;

        @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, auto);
            @include breakpoint(phone) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto);
            }
        }

        @include breakpoint(phone) {
            padding: $space-s;
        }
    }
    &__options-row {
        display: flex;
    }
    &__cell-data {
        display: flex;
    }
    &__note {
        @include font--small();
        color: $text-secondary;
        padding: $space-m;
        margin: 0;
    }
}

.custom-plan {
    &__container {
        display: flex;
    }
    &__portraits-container {
        display: flex;
    }
    &__portrait-container {
        width: 59px;
        height: 59px;
        text-align: center;
    }
    &__portrait {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 100%;
    }
    &__portrait_subtitle {
        @include font--small();
        color: $text-secondary;
    }
    &__title {
        @include font-header();
        @include font--strong();
    }
    &__subtitle {
        @include font--small();
        color: $text-secondary;
    }
}
