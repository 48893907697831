@import '../../../styles/base.scss';

.mobile-search-input {
    &__container {
        display: inline-flex;
        align-items: center;
        width: 100%;
        transition: width 300ms;
        overflow: hidden;

        &--collapsed {
            display: inline-block;
            padding: 10px;
            background: $neutral-bg;
            border-radius: 100px;
            line-height: 14px;
            box-sizing: border-box;
            width: 34px;
            height: 34px;
        }
    }
}
