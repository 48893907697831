@import '../../../styles/base.scss';

.tenant-view {
    &__container {
        border: $std-border;
        border-radius: 8px;
        max-width: 400px;
        background: white;
    }
    &__header {
        @include std-section();
        height: 56px;
        box-sizing: border-box;
        padding: $space-m;
    }
    &__section {
        padding: $space-squish-insert-l;
        &--photo {
            padding: $space-xl;
            display: flex;
            justify-content: center;
        }
    }
    &__photo {
        @include media-radius(4px);
        background-color: $neutral-bg;
        width: 128px;
        height: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        $padding: $space-m;
    }
}
