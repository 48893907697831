@import '../../../styles/base.scss';

.search-input {
    &__container {
        display: inline-block;
        position: relative;
        max-width: 700px;
        flex: 1 1 auto;
        height: 40px;

        &--mobile {
            height: 34px;
        }
    }
    &__input {
        @include font-body();
        @include font--strong();
        padding: $space-s $space-l;
        background-color: $neutral-bg;
        outline: none;
        border: none;
        border-radius: 100px;
        width: 100%;
        line-height: 22px;
        box-sizing: border-box;
        height: 100%;
        &::placeholder {
            @include font-body();
        }

        &--mobile {
            @include font--small();

            &::placeholder {
                @include font-body();
                @include font--small();
            }
        }
    }
    &__icon {
        position: absolute;
        right: 26px;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(phone) {
            display: none;
        }

        &--mobile {
            right: 10px;
        }
    }
}
