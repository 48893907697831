@import '../../../styles/base.scss';

.invoice-item {
    &__container {
        display: flex;
        justify-content: space-between;
        padding: $space-s;
        box-sizing: border-box;
    }
}
