@import '../../../styles/base.scss';

.element-modal {
    &__title-section {
        text-align: center;
    }
    &__title {
        @include font-header();
        @include font--strong();
    }
    &__image {
        @include element-image();
    }
}
