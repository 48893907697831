@import '../../../styles/base.scss';

.trial-note {
    flex: 0 0 auto;
    padding: $space-s;
    text-align: center;
    width: 100%;
    background: $neutral-bg;
    box-sizing: border-box;

    &--warn {
        background: $sunglow-10;
    }
}
