@import '../../../styles/base.scss';

.expandable-list {
    &__container {
        border-style: solid;
        border-color: rgba(34, 34, 34, 0.1);
        border-width: 0 1px 1px 1px;
        margin: 0 1px;

        &--active {
            background: $neutral-bg;
            box-shadow: 0px 0px 0px 1px black;
        }
        &--top {
            border-radius: 4px 4px 0 0;
            border-width: 1px 1px 1px 1px;
        }
        &--bottom {
            border-radius: 0px 0px 4px 4px;
            border-width: 0 1px 1px 1px;
        }
        &--center {
            border-radius: 4px;
            border-width: 1px;
        }
    }
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $space-m;
        box-sizing: border-box;
        &--small {
            padding: $space-s;
        }
    }
}
