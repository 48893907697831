@use 'sass:color';
@import './utils.scss';

// Standard color constants to be adhered to everywhere
$black-alpha-100: rgb(2, 20, 39);
$black-alpha-85: rgba(2, 20, 39, 0.85);
$black-alpha-70: rgba(2, 20, 39, 0.7);
$black-alpha-55: rgba(2, 20, 39, 0.55);
$black-alpha-35: rgba(2, 20, 39, 0.35);
$black-alpha-10: rgba(2, 20, 39, 0.1);
$black-alpha-5: rgba(2, 20, 39, 0.05);
$platinum: rgb(247, 248, 248);

$dartmouth-green: rgb(1, 122, 51);
$dartmouth-green-10: rgba(1, 122, 51, 0.1);

$rose-madder: rgb(228, 37, 53);
$rose-madder-10: rgba(228, 37, 53, 0.1);
$rose-hover: rgba(208, 21, 37, 1);

$bluetiful: rgb(4, 103, 241);
$bluetiful-10: rgba(14, 113, 251, 0.1);

$sunglow: rgb(255, 204, 0);
$sunglow-10: rgba(255, 204, 0, 0.1);
$sunglow-button: rgba(255, 205, 76, 1);
$sunglow-button-hover: rgba(250, 192, 46, 1);
// introduced for better contrast used in normal issue chips
$sand: #9e7116;

$inverted: rgba(255, 255, 255);
$inverted-bg: rgba(255, 255, 255, 0.05);

$sandy-yellow: #f2eee3;
$dusty-blue: #c5dce2;

$error-page-bg: #f2fafd;

// NOTE: arbitrary
$scrollbar-bg: #f5f5f5;
$scrollbar-thumb-bg: #a2a2a2;

// -- Intentions
$accent: $sunglow;
// introduced for better contrast used in normal issue chips
$accent-contrast: $sand;
$accent-bg: $sunglow-10;

$danger: $rose-madder;
$danger-bg: $rose-madder-10;

$success: $dartmouth-green;
$success-bg: $dartmouth-green-10;

$info: $bluetiful;
$info-bg: $bluetiful-10;

$neutral: $black-alpha-100;
$neutral-bg: $black-alpha-5;

// Text related intentions

$text-primary: $black-alpha-100;
$text-secondary: $black-alpha-55;

$text-active: $black-alpha-100;
$text-inactive: $black-alpha-70;
$text-disabled: $black-alpha-35;

// Border related intentions

$border-light: $black-alpha-5;

$border-active: $black-alpha-100;
$border-inactive: $black-alpha-10;

$divider: $black-alpha-10;

// Icon related intensions

$icon-primary: $black-alpha-70;
$icon-secondary: $black-alpha-55;

$icon-active: $black-alpha-100;
$icon-inactive: $black-alpha-70;

$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 24px;
$space-ll: 32px;

$space-xl: 40px;
$space-xll: 50px;
$space-xxl: 72px;

$space-button: 12px;
$space-squish-insert-button: $space-s $space-button $space-s $space-button;

$space-squish-insert-s: $space-xs $space-s $space-xs $space-s;
$space-squish-insert-m: $space-s $space-m $space-s $space-m;
$space-squish-insert-l: $space-m $space-l $space-m $space-l;
$space-squish-insert-ll: $space-l $space-ll $space-l $space-ll;
$space-squish-insert-xl: $space-l $space-xl $space-l $space-xl;

$space-stretch-insert-s: $space-s $space-xs $space-s $space-xs;
$space-stretch-insert-m: $space-m $space-s $space-m $space-s;
$space-stretch-insert-l: $space-l $space-m $space-l $space-m;
$space-stretch-insert-ll: $space-ll $space-l $space-ll $space-l;
$space-stretch-insert-xl: $space-xl $space-l $space-xl $space-l;

// NOTE: Deprecated color constants
$grey70: #565656;
$grey55: #7e7e7e;
$dark-black: #000000;

$border-color: rgba(34, 34, 34, 0.1);
$secondary-accent: rgba(255, 255, 255, 0.2);

$std-border: 1px solid $border-color;
$border-shadow: 0px 0px 0px 1px;

$background-color: #f6f6f6;

$box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
    0px 4px 8px -4px rgba(0, 0, 0, 0.2);

@mixin font-any {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
}

@mixin font-base-serif {
    @include font-any();
    font-family: 'Inter', serif;
}

@mixin font-base {
    @include font-any();
    font-family: 'Inter', sans-serif;
}

//

@mixin font-title {
    @include font-base-serif();
    font-weight: 700;
    font-size: 34px;
    line-height: 1.1;
}

@mixin font-header {
    // Note: not the same as .font__header. It has font--strong as well
    @include font-base-serif();
    font-size: 20px;
    line-height: 1.1;
}

@mixin font-sub-header {
    @include font-base-serif();
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.5;
    text-transform: uppercase;
    line-height: 1.1;
}

@mixin font-body {
    @include font-base();
}

@mixin font-button {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.004em;
}

@mixin font--small {
    font-size: 14px;
}

@mixin font--strong {
    font-weight: 500;
}

@mixin font--secondary {
    color: $text-secondary;
}

@mixin std-button-states {
    &:active:not(:disabled) {
        transition: transform 60ms ease-in;
        transform: scale(0.95);
    }
    &:focus-visible {
        transition: transform 60ms ease-in;
        box-shadow: 0px 0px 0px 1px $border-active;
    }
    &:hover {
        transition: transform 60ms ease-in;
    }
}

@mixin std-button__change-color($color, $color-bg) {
    color: $color;
    background-color: $color-bg;
    &:hover {
        color: $color;
        @if color.alpha($color-bg) > 0.9 {
            // It seems hard to notice opacity change of 0.1 for some colors (black)
            background-color: color.change(
                $color-bg,
                $alpha: color.alpha($color-bg) - 0.3
            );
        } @else {
            background-color: color.change(
                $color-bg,
                $alpha: color.alpha($color-bg) + 0.1
            );
        }
    }
}

@function lighten_on_hover($color) {
    @return color.scale($color, $lightness: 20%);
}

@mixin std-tab {
    @include font--small();
    @include font--strong();

    @include text-crop();
    position: relative;
    background-color: white;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 72px;
    padding: 0;
    background: $neutral-bg;
    white-space: nowrap;
    cursor: pointer;
}

@mixin std-badge {
    width: 8px;
    height: 8px;
    background-color: $danger;
    border-radius: 8px;
    vertical-align: middle;
}

@mixin std-list-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: $space-s;
    background-color: $neutral-bg;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
}

@mixin std-block {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    border: $std-border;
    flex: 0 0 auto;
    overflow: hidden;
}

@mixin std-block-l {
    @include std-block();
    width: 56px;
    height: 56px;
}

@mixin std-icon {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    flex: 0 0 auto;
    & > svg.icon-override {
        width: 100%;
        height: 100%;
        vertical-align: -0.3em;
    }
}

@mixin std-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@mixin iss-media-item {
    display: inline-block;
    width: 150px;
    height: 112px;
    @include media-radius(4px);
}

@mixin card-block(
    $dynamic: false,
    $width: 160px,
    $height: 126px,
    $break-height: 160px
) {
    display: inline-block;
    box-sizing: border-box;
    width: $width;
    height: $height;

    @if $dynamic {
        height: 100%;
    }

    @include breakpoint(phone) {
        width: 100%;

        @if $dynamic {
            height: $height;
        }

        // support for having different height from base when wrapping
        @if $break-height {
            height: $break-height;
        }
    }
}

@mixin icon-small-quickfix($adjust-align: false) {
    width: 16px;
    height: 16px;
    line-height: 16px;

    @if $adjust-align {
        svg.icon-override {
            vertical-align: -0.2em;
        }
    }
}

@mixin element-image() {
    margin: auto;
    width: 352px;
    height: 264px;
    background: $neutral-bg;
    @include media-radius(4px);
    @include breakpoint(phone) {
        width: 272px;
        height: 204px;
    }
}

.light-gray {
    color: #a6adb3;
}

.text__align {
    &--right {
        text-align: right;
    }
}
