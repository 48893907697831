@import '../../../styles/base.scss';

.set-password-modal {
    &__header {
        padding: 25px 20px;
        background-color: white;
        border-bottom: $std-border;
    }
    &__content {
        padding: 25px 20px;
    }
    &__actions {
        text-align: right;
    }
}
