@import '../../../styles/base.scss';

.company-details {
    &__section {
        padding: 24px;

        @include breakpoint(phone) {
            padding: $space-m;
        }
    }

    &__sectionDevision {
        display: flex !important;
        justify-content: space-between !important;

        @include breakpoint(phone) {
            display: block !important;
        }
    }

    &__form {
        box-sizing: border-box;
        text-align: left;
        width: 50%;

        .mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__notch .mdc-floating-label {
            background-color: white;
        }

        .mdc-text-field .mdc-floating-label {
            background-color: white;
            padding: 0px 10px 0px 8px
        }

        .mdc-select--disabled .mdc-notched-outline .mdc-notched-outline__notch .mdc-floating-label {
            background-color: white;
        }

        .mdc-select .mdc-floating-label {
            background-color: white;
            padding: 0px 10px 0px 8px
        }

        .mdc-select {
            margin-bottom: 24px;
        }

        .util__center .profile__btnWrap .std-button--primary {
            padding: 6px 44px;
        }

        @include breakpoint(phone) {
            width: 100%;
            margin-bottom: 62px;
        }
    }

    &__logoUploader {
        width: 40%;
        box-sizing: border-box;

        @include breakpoint(phone) {
            width: 100% !important;
            padding-top: 24px;
            border-top: 1px solid #E8E8E8;
        }

        &__titleWrap {
            display: flex;
        }
    }
}
