@import '../../../styles/base.scss';

.plan-section {
    &__invoice {
        padding: $space-l $space-l $space-s $space-l;

        @include breakpoint(phone) {
            padding: $space-m $space-m $space-s $space-m;
        }
    }
    &__subtitle {
        @include font--small();
        color: $text-secondary;
        padding: $space-squish-insert-m;
    }
    &__package-title {
        @include font--strong();
    }
    &__package-table {
        display: flex;
        flex-direction: column;
    }
    &__package-row {
        display: flex;
        align-items: center;
        width: 100%;
    }
    &__cell {
        flex: 1 1 50%;
    }
    &__package-actions {
        display: flex;
        align-items: center;

        @include breakpoint(phone) {
            flex-direction: column;
        }
    }
    &__storage-action {
        flex: 0 0 auto;
    }

    &__canceal-plan {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.004em;
        font-size: 14px;
        color: rgba(2, 20, 39, 0.85);
        background: transparent;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.hide-detail {
    .expandable-title {
        &__toggle {
            display: none;
        }
    }
    .invoice-date {
        display: none;
    }
    .inovice {
        &__container {
            padding: 0;
        }
        &__total {
            display: none;
        }
        &__next-invoice-note {
            display: none;
        }
    }
    .expandable-list {
        &__container {
            pointer-events: none;
        }
        &__title {
            .std-icon {
                display: none;
            }
            .inovice {
                &__subtotal-container {
                    .currency {
                        display: none;
                    }
                }
            }
        }
    }
}
