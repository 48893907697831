@import './base.scss';
@import '@material/ripple/mdc-ripple.scss';
@import '@material/floating-label/mdc-floating-label.scss';
@import '@material/notched-outline/mdc-notched-outline.scss';
@import '@material/line-ripple/mdc-line-ripple.scss';

@import '@material/textfield/mdc-text-field.scss';
@import '@material/button/mdc-button.scss';

@import '@material/list/mdc-list.scss';
@import '@material/menu-surface/mdc-menu-surface.scss';
@import '@material/menu/mdc-menu.scss';

@import '@material/radio/mdc-radio.scss';
@import '@material/form-field/mdc-form-field.scss';

@import '@material/select/mdc-select.scss';
@import '@material/theme/mdc-theme.scss';

@import '@material/checkbox/mdc-checkbox.scss';

@import '@material/tab-bar/dist/mdc.tab-bar.css';
@import '@material/tab/dist/mdc.tab.css';
@import '@material/tab-scroller/dist/mdc.tab-scroller.css';
@import '@material/tab-indicator/dist/mdc.tab-indicator.css';
@import '@rmwc/icon/icon.css';

@import '../../node_modules/@rmwc/circular-progress/circular-progress.css';
@import '@material/linear-progress/mdc-linear-progress.scss';
@import '../../node_modules/@material/snackbar/dist/mdc.snackbar.css';
@import '../../node_modules/@rmwc/tooltip/tooltip.css';
@import '../components/atoms/StdChip/std-chip.scss';

.mui-datepicker__popover {
    .MuiPickersToolbar-toolbar {
        background-color: black;
    }

    .MuiPickersYear-root:focus {
        color: $grey70;
    }

    .MuiPickersDay-daySelected {
        background-color: $grey70;
        &:hover {
            background-color: lighten($grey70, 20%);
        }
    }

    .MuiPickersDay-current {
        color: $success;
    }

    .MuiPickersDay-daySelected.MuiPickersDay-current {
        color: lighten($success, 20%);
    }

    .MuiPickersYear-yearSelected {
        color: $success;
    }
}

.mdc-tab-bar {
    width: auto;
}

.mdc-tab {
    flex: none;
}

.profile__tabs .mdc-tab:first-child {
    padding-right: 10px;
    padding-left: 10px;
}

.mdc-linear-progress.mdc-custom-thick-progress {
    border-radius: 6px;
    height: 8px;
    &--accent {
        color: $accent;
    }
    &--danger {
        color: $danger;
    }
    & > .mdc-linear-progress__bar {
        & > .mdc-linear-progress__bar-inner {
            border-top: 8px solid;
        }
    }
}

.mdc-linear-progress.custom-progress--greeen {
    & > .mdc-linear-progress__bar {
        & > .mdc-linear-progress__bar-inner {
            border-color: color.adjust($success, $alpha: -0.3);
        }
    }
    & > .mdc-linear-progress__buffer {
        background: color.adjust($success-bg, $alpha: 0.3);
    }
}

.mdc-linear-progress.custom-progress--yellow {
    & > .mdc-linear-progress__bar {
        & > .mdc-linear-progress__bar-inner {
            border-color: color.adjust(#ffc82a, $alpha: -0.3);
        }
    }
    & > .mdc-linear-progress__buffer {
        background: color.adjust(white, $alpha: 0.3);
    }
}

.mdc-linear-progress.custom-progress--blue {
    & > .mdc-linear-progress__bar {
        & > .mdc-linear-progress__bar-inner {
            border-color: color.adjust(#1daad1, $alpha: -0.3);
        }
    }
    & > .mdc-linear-progress__buffer {
        background: color.adjust(white, $alpha: 0.3);
    }
}
.custom-progress--wrapper .custom-progress--blue {
    position: absolute;
    bottom: 0;
}
.custom-progress--wrapper {
    position: relative;
}

.custom-snackbar {
    > .mdc-snackbar__surface {
        @include breakpoint(phone) {
            flex-direction: column;
        }

        > .mdc-snackbar__label {
            @include font-body();
            @include font--strong();
        }
        > .mdc-snackbar__actions {
            .mdc-button__label {
                color: white;
            }
        }
    }
    &--top-center {
        top: 0;
        bottom: auto;
        justify-content: center;
    }
    &--danger {
        > .mdc-snackbar__surface {
            background-color: $danger;
        }
    }
    &--warning {
        > .mdc-snackbar__surface {
            background-color: $accent;
            color: black;
        }
    }
    &--success {
        > .mdc-snackbar__surface {
            background-color: $success;
        }
    }
    &__message {
        display: flex;
        align-items: center;
    }
}

// Explicit overrides for Warning snackbar because normal override above did not work as expected:

 .custom-snackbar--warning > .mdc-snackbar__surface > .mdc-snackbar__label {
     color: black !important; 
 }
 .custom-snackbar--warning .mdc-snackbar__actions .mdc-button__label {
    color: black !important; 
}
// .custom-snackbar--warning > .mdc-snackbar__actions > .mdc-button > .mdc-button__ripple > .mdc-button__label {
//     color: black !important; 
// }

// End of explicit overrides for snackbars


.MuiFormControl-root.MuiTextField-root.mui-datepicker__root {
    background-color: white;
    > .mui-datepicker__input-label--focused:not(.Mui-error):not(.Mui-disabled) {
        color: black;
    }
    > .mui-datepicker__input-root.MuiOutlinedInput-root.Mui-focused:not(.Mui-error):not(.Mui-disabled) {
        & > fieldset.MuiOutlinedInput-notchedOutline {
            border-color: black;
        }
    }
}

.swiper-slide {
    width: initial;
}

// work around for hiding dashboard scrollbar on mobile
.swiper-scrollbar {
    @include breakpoint(phone) {
        display: none;
    }
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.rmwc-circular-progress {
    color: black;
}

.mdc {
    &-radio {
        @include mdc-radio-checked-stroke-color(black);
        @include mdc-radio-ink-color(black);
        &::before {
            background-color: $grey70;
        }
        &::after {
            background-color: black;
        }
    }
    &-select.mdc-select--outlined {
        & > .mdc-notched-outline {
            background-color: white;
        }
        & .mdc-notched-outline__notch .mdc-floating-label {
            pointer-events: none;
        }
    }
    &-menu {
        min-width: 200px;
    }
}

.mdc-checkbox--slim.mdc-checkbox {
    padding: 6px;
    & > .mdc-checkbox__background {
        top: 6px;
        left: 6px;
    }
    & > .mdc-checkbox__native-control {
        width: 100%;
        height: 100%;
    }
    & > .mdc-checkbox__ripple::before {
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
    }
}

// Custom button alterations

.mdc-button {
    @include font-body();
    letter-spacing: initial;
    font-weight: 400;
}

.mdc-button.mdc-button--small {
    height: 20px;
}

.mdc-button.mdc-button--white {
    &:not(:disabled):not(:disabled) {
        color: white;
    }
    &:disabled {
        color: grey;
    }
}

.mdc-button.mdc-button--accent {
    &:not(:disabled):not(:disabled) {
        background: $secondary-accent;
    }
    &:disabled {
        color: grey;
    }
}

.mdc-button:not(:disabled):not(:disabled).mdc-button--dim {
    background: $neutral-bg;
}

.mdc-button:not(:disabled) {
    @include mdc-button-ink-color(black);
}

.mdc-button:not(:disabled).mdc-button--grey {
    @include mdc-button-ink-color($grey70);
}

.mdc-button:not(:disabled).mdc-button--danger {
    & .mdc-button__ripple {
        &:before {
            background-color: red;
        }
        &:after {
            background-color: red;
        }
    }
    color: $danger;
    background: $danger-bg;
}

.mdc-button::before {
    background-color: $grey55;
}

.mdc-button::after {
    background-color: $grey70;
}

// Custom mdc-radio

.mdc-radio--trim.mdc-form-field {
    & > label {
        @include font-body();
        padding-left: $space-m;
    }
    & > .mdc-radio {
        //margin-left: $spcae-m;
        padding: 0;
        & > .mdc-radio__native-control {
            width: 100%;
            height: 100%;
        }
        & > .mdc-radio__ripple::before {
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
        }
    }
}

.mdc-floating-label--float-above {
    z-index: 2 !important;
}

.mdc-text-field__input:-webkit-autofill {
    z-index: 1 !important;
}

.mdc-text-field.mdc-text-field--outlined.mdc-text-field--focused {
    @include mdc-text-field-focused-outline-color(black);
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    .mdc-floating-label.mdc-floating-label--float-above {
    @include mdc-floating-label-ink-color(black);
}

.mdc-text-field:not(.mdc-text-field--invalid) .mdc-text-field__input {
    caret-color: black;
}

.mdc-notched-outline {
    & > .mdc-notched-outline__leading {
        background: #ffffff;
    }
    & > .mdc-notched-outline__notch {
        background: #ffffff;
    }
    & > .mdc-notched-outline__trailing {
        background: #ffffff;
    }
}

// Remove text-area resize
.mdc-text-field__input {
    resize: none;
}

.mdc-select {
    @include mdc-select-focused-outline-color(black);
}

.mdc-select {
    > .mdc-select__anchor {
        width: 100%;
        height: 100%;
    }
}

.mdc-select.mdc-select--focused
    .mdc-floating-label.mdc-floating-label--float-above {
    @include mdc-floating-label-ink-color(black);
}

.mdc-select__dropdown-icon {
    background: none;
    width: 0;
    height: 0;
    bottom: 24px;
    right: 12px;
    border-style: solid;
    border-width: 8px 7.5px 0 7.5px;
    border-color: #444444 transparent transparent transparent;
    z-index: 2;
}

.mdc-select--focused .mdc-select__dropdown-icon {
    background: none;
    border-color: #000000 transparent transparent transparent;
    transform: rotate(180deg) translateX(5px);
}

.mdc-select--disabled .mdc-select__dropdown-icon {
    background: none;
    border-color: #666666 transparent transparent transparent;
}

.mdc-text-field.mdc-text-field--extra-dense {
    height: 40px;
    .mdc-text-field__input {
        padding: 8px 16px;
    }
}

// implement a custom 'dense' select
.mdc-select.mdc-select--dense {
    height: 44px;
    .mdc-select__selected-text {
        padding-left: 16px;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 10px;
        height: 44px;
        min-width: auto;
        overflow: hidden;
    }
    .mdc-select__dropdown-icon {
        bottom: 18px;
    }
    .mdc-floating-label {
        top: 12px;
        &.mdc-floating-label--float-above {
            transform: translateY(-100%) scale(0.75);
        }
    }
}

// ---- attribute selector start

.mdc-custom-attr-select {
    display: flex;
    flex-direction: column;
    width: 200px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    box-sizing: border-box;
    &--focused {
        box-shadow: $border-shadow;
    }
}

.mdc-custom-selector.mdc-select.mdc-select--outlined {
    & .mdc-select__selected-text {
        padding: 23px 15px 12px 15px;
    }
    &
        .mdc-notched-outline__notch
        .mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 19px;
    }
    &
        .mdc-notched-outline--upgraded
        .mdc-floating-label.mdc-floating-label--float-above {
        transform: translateY(-40%) translateX(-7px) scale(0.75);
    }
}

.mdc-custom-selector.mdc-text-field.mdc-text-field--outlined {
    & .mdc-text-field__input {
        padding: 30px 15px 12px 15px;
    }
    &
        .mdc-notched-outline--upgraded
        .mdc-floating-label.mdc-floating-label--float-above {
        transform: translateY(-110%) translateX(-8px) scale(0.75);
    }
}

.mdc-custom-selector.mdc-select.mdc-select--outlined,
.mdc-custom-selector.mdc-text-field.mdc-text-field--outlined {
    & .mdc-notched-outline {
        width: auto;
        left: 6px;
        right: 6px;
        &.mdc-notched-outline--upgraded
            .mdc-floating-label.mdc-floating-label--float-above {
            color: $grey70;
        }
        & .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border: none;
        }
    }
}

/// --- attribute selector end

.mdc-menu-content {
    padding: 0 16px;
}

.mdc-list-item-step {
    display: inline-block;
    & > .mdc-list-item {
        height: auto;
    }
}

.mdc-textfield-custom {
    &.mdc-text-field.mdc-text-field--outlined {
        .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                background-color: $neutral-bg;
            }
        }
        &:not(.mdc-text-field--focused) .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border: none;
            }
        }
    }
}

.tooltip {
    &--danger {
        .rmwc-tooltip-inner {
            background-color: $danger !important;
        }
        .rmwc-tooltip-arrow {
            border-top-color: $danger !important;
        }
    }
    &--warn {
        .rmwc-tooltip-inner {
            background: $accent !important;
        }
        .rmwc-tooltip-arrow {
            border-top-color: $accent !important;
        }
    }
    &--info {
        .rmwc-tooltip-inner {
            background: $info !important;
        }
        .rmwc-tooltip-arrow {
            border-top-color: $info !important;
        }
    }
}