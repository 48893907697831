@import '../../../styles/base.scss';

.element-card {
    background: white;
    padding: $space-m;
    display: flex;
    border-bottom: $std-border;
    &__block {
        flex: 0 0 auto;
        display: inline-block;
        width: 64px;
        height: 64px;
        background-color: $neutral-bg;
        color: grey;
    }
    &__title {
        display: flex;
        align-items: center;
    }
    &__info {
        width: 100%;
        overflow: hidden;
    }
    &__icon {
        align-self: center;
    }
    &__dragging {
        border: none;
        box-shadow: $border-shadow $border-color;
    }
    &__info-icon {
        margin: -2px 0 0 0;
        @include breakpoint(phone) {
            margin: 0;
        }
    }
    &__issue-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__issue-button {
        margin: 0 8px 8px 0;
    }
}
