@import '../../../styles/base.scss';

.edit-manager {
    &__photo {
        padding: $space-xl 0;
    }
    &__btn-save {
        margin-top: 18px;
        justify-content: flex-end;
    }
}
