@import '../../../styles/base.scss';

.storage-banner {
    &__conatiner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: $space-s $space-l;
        background-color: black;
        color: white;
        width: 100%;
        text-align: center;
    }
}
