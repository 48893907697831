@import '../../../styles/base.scss';

.tutorial {
    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
        padding: $space-stretch-insert-l;
        background: $neutral-bg;
        border-radius: 8px;
        width: 100%;
    }
    &__text-wrapper {
        display: flex;
        flex-direction: column;
    }
    &__contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        height: 100%;
        width: 300px;
    }
    &__illustration {
        width: 340px;
        height: 240px;
        object-fit: contain;
    }
}
