@import '../../../styles/base.scss';

.std-icon {
    @include std-icon();

    &--clickable {
        @include css-ripple();
        @include icon-small-quickfix($adjust-align: true);
        padding: $space-s;

        border-radius: 100%;
        cursor: pointer;
    }
    &--small {
        width: 16px;
        height: 16px;
        line-height: 16px;
        svg.icon-override {
            vertical-align: -0.2em;
        }
    }
    &--secondary {
        color: $text-secondary;
    }
}
