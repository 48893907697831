@import '../../../styles/base.scss';

.room-view {
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 400px;
    background: white;
    border: $std-border;

    &__header {
        box-sizing: border-box;
        padding: $space-m;
        border-bottom: $std-border;
    }

    &__title {
        display: flex;
        align-items: center;
    }

    &__divider {
        height: 1px;
        background-color: transparent;
    }

    &__add-ele-btn {
        background: #f2f3f4;
        border: #f2f3f4;
        font-weight: 500;
        color: #000000;
        font-size: 14px;
        line-height: 22px;
        padding: 6px 16px 6px 16px;
    }
    &__info-icon {
        margin-top: -8px;

        @include breakpoint(phone) {
            margin-top: -5px;
        }
    }
}
