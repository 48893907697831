@use 'sass:color';

@import './base.scss';
@import './utils.scss';
@import './overrides.scss';

.std-blank-link {
    @include text-link();

    // span {
    //     @include breakpoint(phone) {
    //         display: none;
    //     }
    // }
}

// TODO: fix video component structure
.std-video {
    &__container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__content {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.std-classic-link {
    @include text-link(blue);
}

.std-badge {
    display: inline-block;
    @include std-badge();
}

.std-section {
    @include std-section();
}

.std-page {
    height: 100vh;

    @include breakpoint(phone) {
        height: initial;
    }
}

.file-uploader {
    display: inline-block;
    position: relative;
}

.dragging-list {
    background-color: $neutral-bg;
}

// Used with RMWC's Ripple component
.ripple-button {
    flex: 0 0 auto;
    padding: $space-s;
}

.std-icon-bullet {
    flex: 0 0 auto;
    white-space: nowrap;

    &__text {
        vertical-align: middle;
    }
}

// --- utility like classes

.std-action-group {
    display: flex;
    flex: 0 0 auto;
}

.std-rounded-border {
    border: $std-border;
    border-radius: 6px;
}

.info {
    &__attributes {
        @include font--small();
        color: $text-secondary;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    &__action {
        margin: 1px;
    }
}

.maps {
    &__modal {
        width: 100%;
        height: 190px;
        background: white;
        border: 1px solid $border-inactive;
    }
}

// --

.form {
    &__line {
        display: flex;
        width: 100%;
    }

    &__line-action-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__line-action-wrapper {
        display: flex;
        flex-direction: column;

        &__1 {
            width: 50%;
        }

        &__2 {
            width: 50%;
        }
    }

    &__line-action {
        display: flex;
        align-items: center;
    }

    &__start {
        display: flex;
        align-items: flex-start;
    }
}

.loading {
    // non unmounting indicators
    &__tab {
        position: relative;
        height: 140px;
        overflow: hidden;
    }

    &__elements {
        position: relative;
        max-height: 100%;
        overflow: hidden;
    }

    &__item {
        position: absolute;
        @include flex-center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
    }

    // unmouting indicators
    &__dashborad {
        @include flex-center;
        width: 100%;
        height: 128px;
    }

    &__issue-add {
        @include flex-center;
        width: 354px;
        height: 128px;
    }

    &__page {
        @include flex-center;
        width: 100vw;
        height: 100vh;
    }
    &__beta_protocol--loader {
        height: 80vh;
        @include breakpoint(phone) {
            height: 60vh;
        }
    }

    &__tabs {
        text-align: center;
        width: 100%;
        height: 42px;
        padding-bottom: $space-l;
    }

    &__element {
        @include flex-center;
        width: 100%;
        height: 96px;
        flex: 1 1 auto;
    }

    &__issue {
        @include flex-center;
        width: 100%;
        height: 170px;
    }

    &__gallery {
        @include flex-center;
        width: 100%;
        height: 132px;
    }

    &__logs {
        width: 100%;
        text-align: center;
    }

    &__select {
        @include flex-center;
        width: 100%;
        height: 64px;
    }

    &__miop-step {
        @include flex-center;
        width: 100%;
        height: 100%;
        flex: 1 0 auto;
    }

    &__api-handler {
        display: flex;
        justify-content: center;
        padding: 60px 10px;
        padding-top: 20px;
    }

    &__miop-sign {
        @include flex-center;
        width: 100%;
        height: 64px;
    }

    &__button {
        margin: 0 $space-l;

        &--white {
            & .rmwc-circular-progress {
                color: white;
            }
        }
    }

    &__dashboard-item {
        @include flex-center;
        width: 354px;
        height: 100px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-squish-insert-m;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
    border-bottom: 2px solid $neutral-bg;
    overflow-x: auto;
    background: inherit;

    &--large {
        padding: $space-squish-insert-l;

        @include breakpoint(phone) {
            padding: $space-squish-insert-m;
        }
    }

    &__location {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    &__title {
        @include breakpoint(phone) {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 160px;
            vertical-align: middle;
        }
    }

    &__slash {
        color: $text-disabled;
        display: inline-block;
        width: 20px;
        text-align: center;
    }
}

.modal {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);

    &__scroller {
        overflow: auto;
        width: 100%;
        height: 100%;
    }

    &__backdrop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;
    }

    &__content {
        margin: $space-xl 0;
        background-color: $background-color;
        border-radius: 2px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
            0px 8px 16px -4px rgba(0, 0, 0, 0.2);
        max-width: 460px;
        width: 460px;
        max-height: 100%;
        border-radius: 8px;

        // NOTE: fixes the issue with box shadow around modals rounded border
        & > :first-child {
            border-radius: 8px 8px 0 0;
        }

        &--small {
            width: 400px !important;
        }

        &--large {
            width: 564px;
        }

        &--white {
            background: white;
        }

        @include breakpoint(phone) {
            width: 100%;
        }
    }

    // Transitons clasess for CSS transition group
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 300ms ease-in;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity 300ms ease-out;
        }
    }

    &__section {
        box-sizing: border-box;
        padding: $space-squish-insert-l;

        // TODO: reduce the number of section spacing types
        &--slim {
            padding: $space-m $space-m $space-l $space-m;
        }

        &--square {
            padding: $space-l;
        }

        &--tall {
            padding: $space-stretch-insert-ll;
        }

        &--large {
            padding: $space-ll;
        }

        &--small {
            padding: $space-m;
        }

        &--top {
            padding: $space-l $space-m $space-m $space-m;
        }

        &--side {
            padding: $space-stretch-insert-l;
        }

        &--slimed {
            padding: 0px 16px 24px 16px;
        }
        &--addProperty {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__saveBtn {
                height: 40px;
                min-width: 135px;
            }
            &__cancelBtn {
                height: 40px;
                min-width: 120px;
            }
        }

        &--ButtonGrp {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            &__selectOptionBtn {
                min-width: 135px;
                height: 40px;
            }
        }

        &--addAttributes {
            display: block;
        }

        &--editAttributes {
            margin-top: 12px;
        }

        &--options {
            padding: 21px 16px 21px 16px;
        }

        &--saveBtn {
            margin-left: auto;
            button {
                height: 40px;
                min-width: 104px;
            }
        }

        &--btnSpace {
            padding: 0px 16px 24px 16px;
        }

        &---buttonGrp {
            padding: 24px;
        }

        &__space {
            margin-left: 20px;
        }

        &__selectors {
            display: flex;
            justify-content: baseline;
            padding: 0px 16px;
        }

        &__datepicker {
            position: relative;
            width: 100%;
            margin-right: 12px;
        }

        &__calendarImg {
            top: 15px;
            position: absolute;
            right: 15px;
        }

        &__photoWrap {
            margin-bottom: 16px;
        }

        &__mediaPosition {
            display: flex;
            justify-content: flex-start;
            padding: 0px 24px 16px 24px;
        }

        &__breadcrump {
            display: flex;
            align-items: center;

            &__wrapper {
                display: flex;
                align-items: flex-end;
                cursor: pointer;
            }

            &__title {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                margin: 0px 12px -4px 8px;
                color: rgba(0, 0, 0, 0.55);
                cursor: pointer;
                border-bottom: 2px solid transparent;

                &__active {
                    font-weight: 500;
                    color: #021d27;
                    text-decoration: underline;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 2px;
                }
            }

            svg {
                color: rgba(0, 0, 0, 0.55);
            }

            img {
                filter: contrast(0.5);
            }

            &__activeImg {
                filter: contrast(1) !important;
            }
        }
        &__optionBtns {
            height: 40px;
            min-width: 120px;
        }
        &--msg {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(2, 20, 39, 0.3);
        }
    }

    &__heading {
        @include font-body();
        @include font--strong();

        color: $grey70;
        vertical-align: middle;
    }

    &__actions {
        display: flex;
        text-align: right;

        &--right {
            justify-content: flex-end;
        }

        &--center {
            justify-content: center;
        }
    }
}

// modal specific styles
.iss-modal {
    &__grade {
        color: $accent;

        &--danger {
            color: $danger;
        }
    }

    &__title {
        text-align: center;
        word-break: break-word;
    }

    &__desc-title {
        @include font--secondary();
        vertical-align: top;
        display: inline-block;
        width: 100px;
    }

    &__desc-value {
        display: inline-block;
        width: 280px;
    }

    &__location {
        @include font--secondary();
        width: 100px;
    }

    &__media {
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
    }

    &__media-item {
        @include iss-media-item();
        background-color: #ffffff;
    }

    &__media-item-black {
        @include iss-media-item();
        background-color: $neutral-bg;
    }

    &__breadcrumbs {
        display: flex;
        flex-wrap: wrap;
    }

    &__breadcrumb {
        display: flex;
        align-items: center;
        margin: 4px 0px;
    }
}

.error {
    &__page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-color: $error-page-bg;
    }
    &__button-stack {
        display: flex;
        flex-direction: column;
        height: 170px;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__illustration {
        width: 300px;
    }

    &__message {
        display: flex;
        flex-direction: column;
        padding: 56px;
        height: 100%;
        width: 768px;
        justify-content: center;
        max-width: 540px;
        flex: 1 1 auto;

        @include breakpoint(phone) {
            max-width: 340px;
            padding: $space-m;
        }
    }
}

.attribute {
    &__container {
        display: flex;
    }

    &__column {
        flex: 0 0 50%;
        word-break: break-word;
    }
}

.fileBtn {
    min-width: 170px;
}
.header-pill {
    font-size: 10px !important;
    margin-left: 6px;
    pointer-events: none;
}

.beta-mode-modal-content {
    max-width: 590px;
    width: 590px;
    .beta-mode-modal {
        padding: 85px 36px 29px 43px;
        .beta-selector {
            margin-bottom: 48px;
            .modal-header {
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                text-align: center;
                color: #021427;
                margin-bottom: 36px;
            }
            .modal-info {
                &__1 {
                    margin-bottom: 15px;
                }
                &__2 {
                    margin-bottom: 26px;
                }
            }
            .modal-condition-check {
                .inline-m {
                    margin-right: 0;
                }
            }
        }
    }
}
.beta-modal-actions {
    justify-content: space-between;
    .beta-accept {
        border: none;
        color: rgba(0, 0, 0, 0.85);
        background-color: #ffcd4c;
        &:disabled {
            color: rgba(0, 0, 0, 0.55);
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:hover {
            border: none !important;
        }
    }
}

.close-confirm-modal-content {
    max-width: 420px;
    width: 420px;
    .close-confirm-modal {
        padding: 28px 35px 29px 43px;
        .close-confirm-modal-header {
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 60px;
        }
        .confirmation-modal {
            margin-bottom: 66px;
            text-align: center;
            .modal-header {
                text-align: center;
                color: #353332;
                margin-bottom: 36px;
            }
            .modal-info {
                &__1 {
                    margin-bottom: 15px;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                }
                &__2 {
                    margin-bottom: 26px;
                }
            }
            .modal-condition-check {
                .inline-m {
                    margin-right: 0;
                }
            }
        }
    }
}
.confirmation-modal-actions {
    justify-content: space-between;
    .confirmation-cancel {
        border: none;
        min-width: 135px;
        color: rgba(0, 0, 0, 0.85);
        background-color: #ffcd4c;
        &:disabled {
            color: rgba(0, 0, 0, 0.55);
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:hover {
            border: none !important;
        }
    }
}

.accExp-Warning-modal-content {
    max-width: 590px;
    width: 590px;

    @include breakpoint(phone) {
        max-width: 344px;
        width: 344px;
    }

    .accExp-Warning-modal {
        padding: 66px 71px 70px 71px;

        @include breakpoint(phone) {
            padding: 86px 23px 60px 23px;
        }
        .accExp-Warning-content-wrapper {
            margin-bottom: 0px;
            .modal-header {
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #021427;
                margin-bottom: 83px;

                @include breakpoint(phone) {
                    margin-bottom: 63px;
                }
            }
            .modal-info {
                &__2 {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 19px;
                    color: #021427;
                }
                &__highlight {
                    color: #ffc82a;
                    overflow-wrap: break-word;
                }
            }
        }
    }
}
.accExp-Warning-action-button {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;
    .accExp-Warning-action-back {
        border: none;
        color: rgba(0, 0, 0, 0.85);
        background-color: #ffcd4c;
        &:disabled {
            color: rgba(0, 0, 0, 0.55);
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:hover {
            border: none !important;
        }
    }
}

// user persona
.user-persona-selector-modal-content {
    max-width: 590px;
    width: 590px;

    @include breakpoint(phone) {
        max-width: 344px;
        width: 344px;
    }

    .user-persona-selector-modal {
        padding: 66px 71px 70px 71px;

        @include breakpoint(phone) {
            padding: 86px 23px 60px 23px;
        }
        .user-persona-selector-content-wrapper {
            margin-bottom: 0px;
            .modal-header {
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #021427;
                margin-bottom: 56px;

                @include breakpoint(phone) {
                    margin-bottom: 63px;
                }
            }
            .modal-info {
                &__2 {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    line-height: 19px;
                    color: #021427;
                }
                &__highlight {
                    color: #ffc82a;
                    overflow-wrap: break-word;
                }
            }
        }
    }
}
.user-persona-selector {
    &__selection-list {
        margin-top: 55px;
    }
    &__list-item-highlight {
        color: #b3b9be;
        margin-left: 5px;
    }
}
.user-persona-selector-action-button {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;
    .user-persona-selector-action-proceed {
        border: none;
        color: rgba(0, 0, 0, 0.85);
        background-color: #ffcd4c;
        &:disabled {
            color: rgba(0, 0, 0, 0.55);
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:hover {
            border: none !important;
        }
    }
}

// cleaning warning
.warn-cleaning-border {
    border: 1px solid $danger;
}
.multiSelector__select-input {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-notched-outline__trailing {
        border: none !important;
    }
}
.multiSelector__wrapper__full {
    position: relative;
    .mdc-menu {
        position: unset;
        width: 100%;
    }
    .mdc-menu-surface--anchor {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        z-index: 999;
    }
}
.multiSelector__wrapper__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.edit-manager-modal .modal-header__container {
    padding: 20px 18px;
}
