@import './base.scss';
@import './utils.scss';

// Note: the css throughout the app is global
// css modules are not used yet so conflicts are possible

@import './components.scss';
@import './pages.scss';
@import './animations.scss';
@import './util-classes.scss';

html,
body {
    min-height: 100vh;
}

body {
    @include font-body();
    color: $text-primary;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

// Note: the this doesn't conver everything
// so sometimes manual overrides are necessary
:root {
    --mdc-theme-on-primary: white;
    --mdc-theme-on-secondary: white;
    --mdc-theme-primary: black;
    --mdc-theme-secondary: #565656;
}

.font {
    // Font types
    &__title {
        @include font-title();
    }
    &__header {
        @include font-header();
        @include font--strong();
    }
    &__sub-header {
        @include font-sub-header();
    }
    &__body {
        @include font-body();
    }

    // Font styles
    &--small {
        @include font--small();
    }
    &--secondary {
        @include font--secondary();
    }
    &--strong {
        @include font--strong();
    }
}

.stack {
    &-xs {
        margin-bottom: $space-xs;
    }
    &-s {
        margin-bottom: $space-s;
    }
    &-m {
        margin-bottom: $space-m;
    }
    &-l {
        margin-bottom: $space-l;
    }
    &-ll {
        margin-bottom: $space-ll;
    }
    &-xl {
        margin-bottom: $space-xl;
    }
    &-xxl {
        margin-bottom: $space-xxl;
    }
}

.inline {
    &-xs {
        margin-right: $space-xs;
    }
    &-s {
        margin-right: $space-s;
    }
    &-m {
        margin-right: $space-m;
    }
    &-l {
        margin-right: $space-l;
    }
    &-ll {
        margin-right: $space-ll;
    }
    &-xl {
        margin-right: $space-xl;
    }
}

.color {
    &-white {
        color: #ffffff;
    }
    &-grey {
        color: $text-secondary;
    }
    &-approve {
        color: $success;
    }
    &-danger {
        color: $danger;
    }
    &-black {
        color: black;
    }
    &-warn {
        color: $accent;
    }
}

.absolute-input {
    @include absolute-input();
    cursor: pointer;
}

.version-text {
    @include font--small();
    position: absolute;
    top: 10px;
    left: 10px;
}

.util {
    &__none {
        display: none;
    }
    &__pointer {
        cursor: pointer;
    }
    &__inline {
        display: inline-block;
    }
    &__center {
        text-align: center;
    }
    &__wrap {
        flex-wrap: wrap;
    }
    &__ellipsis {
        //TODO: unify all use cases with the mixin
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__hidden {
        visibility: hidden;
    }
    &__float {
        float: right;
    }
    &__inflexible {
        flex: 0 0 auto;
    }

    // NOTE: these flex utility classes usually allow to skip a name
    // in the context of BEM, howeverer, components constructed this way
    // are not implmenatation independent in structure
    // TODO: deprecate
    &__flex {
        display: flex;
    }
    &__flex-align {
        display: flex;
        align-items: center;
    }
    &__flex-right {
        margin-left: auto;
    }
    &__flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }
    &__flex-column {
        display: flex;
        flex-direction: column;
    }
    &__flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    // ----

    &__text-norwap {
        white-space: nowrap;
    }
    &__error {
        box-shadow: 0px 0px 0px 1px red;
    }
    &__no-events {
        pointer-events: none;
    }
    &__chip-wrapper {
        display: flex;
    }
    &__chip-text {
        max-width: 300px;
        display: inline-block;
    }
}

// Custom scrollbar styles

// Firefox specific scrollbar styles
* {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-bg $scrollbar-bg;
}

*::-webkit-scrollbar {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $scrollbar-bg;
}

*::-webkit-scrollbar {
    width: 11px;
    background-color: $scrollbar-bg;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $scrollbar-thumb-bg;
    border: 3px solid $scrollbar-bg;
}

.no-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
}

.dltModal {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        svg {
            color: #000000;
        }
        &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            margin: 0;
            color: #353332;
            strong {
                color: #ffcd4c;
                font-weight: 500;
            }
        }
    }
    &-dltMessage {
        padding: 30px 25px;
        text-align: center;
    }
}
.form_select_overflow .mdc-select__selected-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form_select {
    display: block;
    width: 100%;
}

.profile__setting .mdc-text-field--disabled .mdc-text-field__input,
.profile__setting .mdc-select--disabled .mdc-select__selected-text {
    border: 1px solid #a7a7a7 !important;
    color: #1e1e1e;
    border-radius: 4px;
}

.profile__setting .mdc-select--disabled .mdc-select__selected-text {
    border: 1px solid #959595;
    border-radius: 4px;
}

.disable_property {
    .mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        display: none;
    }

    .mdc-select--outlined
        .mdc-select__anchor
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        display: none;
    }
}

.modal_section_photo {
    padding: 0px;
}
.modal_section_photo {
    .file-picker__add {
        background-color: transparent;
    }
    .gallery__title {
        text-transform: uppercase;
        font-weight: 500;
        color: #565656;
    }
}

.tendant_modal_header {
    padding: 19px 19px 17px 30px !important;
    border-bottom: 1px solid rgba(46, 46, 46, 0.26);
    justify-content: space-between;

    .modal-header__title {
        display: none;
    }
}

.element_modalBorder .modal__content {
    border: 1px solid rgba(2, 20, 39, 0.3);
}

.gallery_items_margin {
    margin: 0 !important;
}

.element_modalBorder {
    border: 1px solid rgba(2, 20, 39, 0.3);
}
.modal-header-centerNode {
    justify-content: space-between;
    .modal-header__title {
        display: none;
    }
}
.object-house-modal {
    .paperclip-icon {
        .std-icon {
            width: 15px;
        }
    }
    .modal__heading {
        color: #000000;
    }
    .modal__section__breadcrump {
        padding: 16px;
    }
    .gallery__item {
        &:first-child {
            margin: 0;
        }
        .file-picker__add {
            background-color: transparent;
        }
    }
}

.emailDisabled {
    .mdc-floating-label {
        background-color: #ffffff;
        padding: 0px 2px;
    }
}

.emailDisabled {
    opacity: 0.6;
}

.modal__section__saveButton {
    display: flex;
    justify-content: flex-end;

    button {
        min-width: 120px;
        height: 40px;
    }
}
.modal__section__add_roomBtn {
    margin-top: 2rem;
    margin-bottom: 14px;
}

// warning text
.warn_cleaning-heading {
    color: $danger;
}
.warn-cleaning {
    .inline-l {
        @include breakpoint(phone) {
            margin-right: 0px;
        }
    }
}
.betaMode-invoice {
    background-color: rgba(255, 200, 42, 0.1) !important;
    color: #ffc82a !important;
    border: 1px solid #ffc82a;
    height: 160px !important;
    .invoice-card__subtitle {
        color: #ffc82a;
    }
}
