@import '../../../styles/base.scss';

.overlay {
    z-index: 4;
}

.camera {
    &__container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: white;
    }
    &__output {
        width: 100%;
        height: 100%;
    }
    &__controls {
        position: absolute;
        bottom: 0;
        left: 50%;
        background: rgba(255, 255, 255, 0.65);
        padding: 10px;
        width: 300px;
        transform: translatex(-50%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%) scale(1.3); // Make the UI bigger without braking semantics
    }
    &__picture-preview {
        position: absolute;
        bottom: $space-m;
        right: $space-m;
        width: 400px;
        height: auto;
        border-radius: 6px;
        border: 2px solid white;
        background: black;
        &--hidden {
            display: none;
        }
    }
}
