@import '../../../styles/base.scss';

.element-quick-form {
    padding: $space-m;

    &__content {
        position: relative;
    }

    &__buttons {
        z-index: 2;
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
