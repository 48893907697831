@import './base.scss';

.dashboard {
    &__page {
        min-height: 100vh;
    }
    &__actions {
        display: flex;
    }
    &__section {
        display: flex;
        flex: 0 0 auto;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: $space-xl;
        @include breakpoint(phone) {
            display: block;
            margin-bottom: $space-m;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: $space-xl $space-l $space-s $space-l;
        overflow: hidden;
        @include breakpoint(tablet) {
            display: block;
        }
        @include breakpoint(phone) {
            display: block;
            padding: $space-l $space-m 0 $space-m;
        }
    }
    &__expandable {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    &__load-more {
        margin: $space-m 0;
        text-align: center;
    }
    &__search {
        width: 405px;
        @include breakpoint(phone) {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.object {
    &__page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        height: 100vh;
        @include breakpoint(phone) {
            display: block;
            height: initial;
        }
    }
    &__picture {
        @include media-radius(8px);
        width: 100%;
        height: 203px;
        box-sizing: border-box;
        background: $neutral-bg;
        border: $std-border;
    }
    &__content {
        display: flex;
        flex: 1;
        overflow: hidden;
        @include breakpoint(phone) {
            flex: initial;
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &__main {
        box-sizing: border-box;
        padding: $space-l;
        background-color: $neutral-bg;
        overflow: hidden;
        width: 100%;
        flex: 0 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        @include breakpoint(phone) {
            padding: $space-s;
        }
    }
    &__sidebar {
        flex: 1 0 auto;
        display: inline-block;
        box-sizing: border-box;
        border-right: 1px solid $border-inactive;
        background-color: white;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 4px;
        border-bottom: 1px solid $border-inactive;
        width: 321px;
        @include breakpoint(phone) {
            display: block;
            overflow: hidden;
            border-right: none;
            width: 100%;
        }
    }
    &__info {
        box-sizing: border-box;
        padding: $space-l;
        width: 321px;
        @include breakpoint(phone) {
            width: 100%;
        }
    }
    &__tabs {
        display: flex;
        box-sizing: border-box;
        width: 321px;
        @include breakpoint(phone) {
            width: 100%;
        }
    }
    &__attribute-list {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
    &__attachment-list {
        cursor: pointer;
    }
    &__type {
        cursor: pointer;
    }
    &__description {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
    &__barcontent {
        box-sizing: border-box;
        padding: $space-m;
        @include breakpoint(phone) {
            width: 100%;
        }
    }
    &__action {
        padding: $space-s;
    }
}

.property {
    &__page {
        min-height: 100vh;
    }
    &__image {
        width: 100%;
        height: 264px;
        @include media-radius(8px);
    }
    &__wrapper {
        display: flex;
        flex-direction: column;

        flex-grow: 1;
        overflow: hidden;
        @include breakpoint(phone) {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &__section {
        padding: $space-l;
        @include breakpoint(phone) {
            padding: $space-m;
        }
    }
    &__info {
        border-bottom: 2px solid $neutral-bg;
    }
    &__search-section {
        @include std-section();
        @include breakpoint(phone) {
            flex-direction: column;
            align-items: flex-start;
            height: 120px;
        }
    }
    &__search {
        display: inline-block;
        width: 400px;
        @include breakpoint(phone) {
            width: 100%;
        }
    }
    &__count-container {
        flex: 1 1 33%;
        @include breakpoint(phone) {
            flex: 0 0 auto;
        }
    }
    &__add-container {
        flex: 1 1 33%;
        text-align: right;
        @include breakpoint(phone) {
            text-align: left;
            flex: 0 0 auto;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: $space-m $space-l $space-m $space-l;
        @include breakpoint(phone) {
            padding: $space-s $space-m $space-s $space-m;
            overflow: initial;
        }
    }
    &__subtitle {
        // NOTE: exception to the standard fonts
        @include font-base();
        @include font--strong();
        font-size: 26px;
        line-height: 30px;
    }
    &__count {
        padding: 5px 10px;
        width: 30px;
        height: 29px;

        // Note: non standard color, yet
        background: #f2f3f4;
        border-radius: 7px;
        color: rgba(2, 20, 39, 0.35);
        box-sizing: border-box;
        vertical-align: text-bottom;
        line-height: 30px;
    }
    &__items {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: $space-l;
    }
}

.login {
    &__page {
        height: 100vh;
        overflow: hidden;
        @include breakpoint(phone) {
            // overflow-x: hidden;
            // overflow-y: auto;
        }
    }
    &__header {
        background: var(--web-neutrals-grey-10, #f3f1f1);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25%;
        max-height: 176px;
    }
    &__content {
        padding: 25px 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 75%;
        box-sizing: border-box;
    }
    &__form {
        text-align: start;
    }
    &__note {
        text-align: center;
    }
    &__card {
        box-sizing: border-box;
        width: 458px;
        .font__title {
            text-align: center;
        }
        @include breakpoint(phone) {
            max-width: 458px;
            width: 100%;
            .font__title {
                color: var(--web-primary-dark-grey, #2d2e2f);
                text-align: center;
                font-family: Inter;
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px; /* 123.077% */
            }
        }
    }
    &__recover-password {
        text-align: right;
    }
    &__recover-note {
        color: #949394;
        @include font--strong();
        @include font--small();
        text-decoration: underline;
    }
    &__recover-note:hover {
        color: black;
        text-decoration: underline;
    }
    &__register {
        text-align: center;
    }
    &__register-note {
        color: #949394;
        text-decoration: underline;
        @include font--strong();
    }
    &__register-note:hover {
        color: black;
        text-decoration: underline;
    }
    &__logo img {
        width: 170px;
        height: 30px;
    }
    &__description {
        @include font--small();
        box-sizing: border-box;
        width: 324px;
        margin: auto;
        line-height: 1.4;
        @include breakpoint(phone) {
            box-sizing: border-box;
            width: 100%;
        }
    }
    &__note-container {
        display: flex;
        justify-content: center;
    }
    &__version {
        @include font--small();
        color: #949394;
        @include breakpoint(tablet-max) {
            margin-top: 20px;
        }
    }
}

// TODO: used all over the place, move to util__ section
.sidebar-title {
    margin: 0 0 0 $space-s;
    flex: 0 0 auto;
}

.protocol__page {
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    // overflow-x: hidden;
    // min-height: 100vh;
    // height: 100vh;

    // @include breakpoint(phone) {
    //     display: block;
    //     height: 100%;
    // }
}
.protocol__page--success-wrapper {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    .protocol__wrapper {
        height: calc(100vh - 180px);
        overflow: auto;
        flex: unset;
    }
    .protocol_presence__wrap {
        height: 100%;
        overflow: auto;
    }
    @include breakpoint(phone) {
        .protocol__wrapper {
            height: calc(100vh - 260px);
        }
        .protocol-step__sidebar {
            height: unset;
        }
    }
}
.protocol__page--failed-wrapper {
    height: 100vh;
    overflow: hidden;
    .protocol__wrapper {
        height: calc(100vh - 125px);
        overflow: auto;
        flex: unset;
    }
    .protocol_presence__wrap {
        height: 100%;
        overflow: auto;
    }
    @include breakpoint(phone) {
        .protocol__wrapper {
            height: calc(100vh - 180px);
        }
        .protocol-step__sidebar {
            height: unset;
        }
    }
}
.protocol__page--height {
    @include breakpoint(phone) {
        .protocol-overview__container {
            padding-bottom: 0;
            overflow: auto;
        }
    }
}
// .protocol__page--height {
//     min-height: 100%;
//     height: calc(100vh - 150px);
//     overflow: hidden;
//     @include breakpoint(desktop) {
//         height: calc(100vh - 100px);
//     }
//     @include breakpoint(phone) {
//         height: calc(100vh - 220px);
//         .protocol-step__sidebar {
//             height: unset;
//         }
//     }

//     .protocol__wrapper {
//         height: calc(100% - 80px);
//         @include breakpoint(phone) {
//             overflow: hidden;
//             height: calc(100% - 50px);
//         }
//     }
//     .protocol-overview__container {
//         overflow: auto;
//         padding-bottom: 0;
//     }
// }

.protocol__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    background: $neutral-bg;
    padding: $space-s;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include breakpoint(phone) {
        overflow-y: auto;
    }
}

.protocol__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.1);
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    @include breakpoint(desktop) {
        overflow-y: auto;
    }
    // fixes wierd summary ipad oveflow issue
    @include breakpoint(tablet) {
        overflow-y: auto;
    }
}

.protocol-step__section {
    padding: $space-m;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    &-l {
        padding: $space-l;
    }
}

.protocol-step__sidebar {
    display: flex;
    flex-direction: column;
    flex: 0 0 300px;
    border-right: $std-border;

    @include breakpoint(phone) {
        height: 100%;
        border-bottom: $std-border;
    }

    &-top {
        overflow: auto;
        box-sizing: border-box;
        padding: $space-stretch-insert-l;
        flex: 1 1 100%;
        @include breakpoint(phone) {
            overflow: initial;
        }
    }
    &-bottom {
        padding: $space-l;
    }
}

.protocol-step__container {
    display: flex;
    flex: 1;
    border-radius: 8px;
    border: $std-border;
    margin: $space-m;
    height: 100%;
    overflow: hidden;
    @include breakpoint(phone) {
        display: block;
        overflow: auto;
    }
}

// used in meter, room & item inspection
.protocol-step__content {
    width: 100%;
    overflow: auto;
}

.protocol-step__outline {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 6px;
    box-sizing: border-box;
}

// TODO: move into a seprate component scss file
.protocol-summary__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    justify-content: center;
}

.protocol__action {
    padding: $space-m;
}

.ad {
    &-card {
        &__container {
            display: inline-block;
            box-sizing: border-box;
            width: 256px;
            height: 166px;
            background-color: #ffffff;
            border-radius: 4px;
            border: $std-border;
            padding: $space-m;
        }
        &__title {
            @include font--strong();
            @include text-ellipsis(100%);
        }
        &__sub-title {
            @include font--small();
            @include text-ellipsis(100%);
            color: $text-secondary;
        }
        &__day-count {
            @include font--small();
            color: $text-secondary;
        }
    }
    &-page {
        &__dashboard {
            display: flex;
            flex-direction: column;
            align-items: center;
            &--preview {
                align-items: flex-start;
            }
        }
        &__public {
            display: flex;
            align-items: stretch;
            flex-wrap: nowrap;
            width: 100%;
            @include breakpoint(tablet) {
                flex-wrap: wrap-reverse;
            }
            @include breakpoint(phone) {
                flex-wrap: wrap-reverse;
            }
        }
    }

    &__block-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: white;
    }

    &__overlay-container {
        position: relative;
        &:hover .ad__block-overlay {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    &-header {
        &__container {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            background-color: black;
            color: white;
            width: 100%;
            padding: $space-squish-insert-m;
            @include breakpoint(phone) {
                display: block;
                padding: $space-squish-insert-s;
            }
        }
        &__logo {
            display: inline-block;
            flex: 0 0 auto;
        }
        &__link {
            @include text-link(white);
            flex: 0 0 auto;
            display: inline-block;
            padding: 8px;
        }
        &__image {
            width: 24px;
            height: 25px;
        }
        &__action-bar {
            display: inline-flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }
        &__actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        &__status {
            display: flex;
            align-items: center;
            border-radius: 4px;
            border: 1px solid $secondary-accent;
            flex-wrap: wrap;
            justify-content: center;
            &--inactive {
                color: $grey55;
            }
        }
        &__infobox {
            flex: 0 0 auto;
            padding: $space-squish-insert-s;
        }
    }

    &-content {
        &__container {
            width: 100%;
            height: 100%;
            flex: 1 3 100%;
        }
        &__gallery {
            width: 100%;
            height: 513px;
            background: $neutral-bg;
            overflow: hidden;
        }
        &__gallery-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &__snow-chip {
            position: absolute;
            top: 50%;
            right: 60px;
            transform: translate(0, -50%);
            z-index: 2;
            @include breakpoint(phone) {
                right: 20px;
            }
        }
        &__count-card {
            position: absolute;
            left: 160px;
            bottom: 24px;
            z-index: 2;
            @include breakpoint(phone) {
                left: 40px;
            }
        }
        &__main {
            padding: $space-xl;
            padding-bottom: 0;
            max-width: 696px;
            margin: auto;
            @include breakpoint(phone) {
                padding: $space-m;
            }
        }
        &__block {
            border: $std-border;
            border-radius: 4px;
            width: 332px;
            box-sizing: border-box;
            @include breakpoint(phone) {
                width: 100%;
            }
        }
        &__attributes {
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            display: flex;
            justify-content: space-between;
            padding: $space-m;
        }
        &__attribute-column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include breakpoint(phone) {
                width: 100%;
            }
        }
        &__documents {
            border: $std-border;
            border-radius: 4px;
            width: 100%;
        }
        &__conact {
            display: flex;
            justify-content: space-between;
        }
        &__owner-details {
            border: $std-border;
            border-radius: 4px;
            padding: $space-m;
        }
        &__location {
            width: 100%;
            height: 336px;
            background: $neutral-bg;
        }
    }

    &-description {
        &__container {
            white-space: pre-wrap;
            line-height: 1.7;
        }
    }

    &-documents {
        &__container {
            height: 300px;
            overflow: auto;
        }
    }

    &-info-bar {
        &__container {
            flex: 3 1 430px;
            max-width: 430px;
            padding: $space-ll;
            box-sizing: border-box;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
                0px 8px 16px -4px rgba(0, 0, 0, 0.2);

            // Need for the box shadow to render over google map
            z-index: 1;
            @include breakpoint(tablet) {
                width: 100%;
                max-width: initial;
            }
            @include breakpoint(phone) {
                width: 100%;
                max-width: initial;
                padding: $space-stretch-insert-m;
            }
        }
        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;

            @include breakpoint(phone) {
                padding: $space-s;
            }
        }
        &__box {
            border: $std-border;
            border-radius: 4px;
        }
        &__item {
            padding: $space-l;
        }
        &__properties {
            display: flex;
            justify-content: space-between;
        }
    }
}

.trial {
    &-cancel {
        &__header {
            padding: 0 $space-s;
            padding-top: $space-xl;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        &__container {
            display: flex;
            flex-direction: column;
            background: white;
            min-height: 100vh;
        }

        &__section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            padding-bottom: 40px;
            box-sizing: border-box;
        }

        &__feedback {
            display: inline-block;
            max-width: 520px;
            text-align: left;
            padding: $space-l;
            border-radius: 4px;
            border: $std-border;
            box-sizing: border-box;
        }

        &__account-option {
            width: 100%;
            max-width: 520px;
        }
        &__note {
            max-width: 520px;
            text-align: center;
        }
    }
    &__terms-link {
        color: #949394;
        text-decoration: underline;
    }
    &__terms-link:hover {
        @include text-link(black);
        text-decoration: underline;
    }
}
