@import '../../../styles/base.scss';

.message-overlay {
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    &__note {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #000000;
        box-shadow: 0px 4px 74px 19px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: $space-l $space-ll;
        color: white;
        margin: 0 $space-l;

        @include breakpoint(phone) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
    &__action {
        flex: 0 0 auto;
    }
}
