@import '../../../styles/base.scss';

.std-step {
    display: inline-block;
    flex: 0 0 auto;
    white-space: nowrap;
    & > span {
        vertical-align: middle;
    }

    &--selected {
        & > span {
            border-bottom: 1px solid black;
        }
    }

    &--highlighted {
        color: $danger;
        &.std-step--selected {
            & > span {
                border-bottom: 1px solid $danger;
            }
        }
    }
    &--inactive {
        color: $text-secondary;
        &.std-step--selected {
            & > span {
                border-bottom: 1px solid $text-secondary;
            }
        }
    }
}
