@import '../../../styles/base.scss';

.offline-bar {
    &__container {
        background-color: black;
        color: white;
        padding: $space-squish-insert-l;
        transition: background-color 300ms linear;
        box-sizing: border-box;

        &--success {
            background-color: $success;
            color: white;
        }
        &--async {
            background-color: #1daad1;
            color: white;
        }
        &--async-danger {
            background-color: #d23b3d;
            color: white;
        }
        &--async-success {
            background-color: #44a98c;
            color: white;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include breakpoint(phone) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        &__checkinTime {
            margin-top: 5px;
        }
    }

    &__text {
        margin-right: $space-m;
        @include breakpoint(phone) {
            width: 100%;
            margin-right: 0;
            margin-bottom: $space-s;
        }
    }
    &__actions {
        display: flex;
        & > :first-child {
            margin-right: $space-m;
        }
        @include breakpoint(phone) {
            flex-wrap: wrap;
            & > :first-child {
                margin-right: 0;
                margin-bottom: $space-s;
            }
        }
    }
    &__offline-actions {
        display: flex;
        & > :first-child {
            border-radius: 32px 0px 0px 32px;
            min-width: 150px;
        }
        & > :last-child > .std-button {
            border-radius: 0px 32px 32px 0px;
            padding-left: 8px;
            padding-right: 10px;
            border-left: 1px solid $black-alpha-85;
            height: 100%;
        }
        & > :last-child > .std-button:hover {
            border-radius: 0px 32px 32px 0px;
            padding-left: 8px;
            padding-right: 10px;
            border-left: 1px solid $black-alpha-85;
        }
    }
    &__offline-danger-actions {
        display: flex;
        & > :first-child {
            border-radius: 32px;
            min-width: 150px;
        }
        // & > :last-child > .std-button {
        //     border-radius: 0px 32px 32px 0px;
        //     padding-left: 8px;
        //     padding-right: 10px;
        //     border-left: 1px solid $black-alpha-85;
        //     height: 100%;
        // }
        // & > :last-child > .std-button:hover {
        //     border-radius: 0px 32px 32px 0px;
        //     padding-left: 8px;
        //     padding-right: 10px;
        //     border-left: 1px solid $black-alpha-85;
        // }
    }

    &__async-mode-switch {
        border-color: white;
        background-color: white;
    }

    &__async-mode-switch:hover:not(:disabled) {
        border-color: white;
    }

    // &__indication-height {
    //     height: 150px;
    //     @include breakpoint(desktop) {
    //         height: 100px;
    //     }
    //     @include breakpoint(phone) {
    //         height: 220px;
    //     }
    // }
}
