@import '../../../styles/base.scss';

.list__item {
    @include std-list-item();
    &--inactive {
        background-color: inherit;
    }
    &--dragging {
        box-shadow: $border-shadow $border-active;
        background-color: white;
    }
}
