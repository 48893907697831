@import '../../../styles/base.scss';

.trial-modal {
    padding: $space-l;
    text-align: center;

    &__highlights {
        text-align: left;
        padding: $space-l;
        border-radius: 4px;
        border: $std-border;
    }

    &__actions {
        position: relative;
    }

    &__logout {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__mark {
        color: $grey55;
        transform: scale(1.4);
    }
}
