@import '../../../styles/base.scss';

.avatar {
    &__container {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        padding: 3px;
        border: 1px solid #1daad1;
        box-sizing: border-box;
    }
    &__content {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: $neutral-bg;
        line-height: 28px;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
    }
}
