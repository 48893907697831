@import '../../../styles/base.scss';

.protocol-card {
    display: inline-block;
    border: $std-border;
    box-sizing: border-box;
    width: 280px;
    height: 125px;
    padding: $space-m;
    border-radius: 4px;
    &__title {
        @include font--strong();
        @include text-ellipsis($width: 100%);
    }
    &__sub-title {
        @include text-ellipsis($width: 100%);
        color: $text-secondary;
    }
    &__details {
        min-height: 32px;
        margin-bottom: 12px;
        &__title {
            min-height: 20px;
            color: #ffc82a;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            span.offline-status {
                color: #000;
                background-color: #cdd1d4;
                padding: 5px;
                border-radius: 65px;
                font-size: 14px;
                font-weight: 400;
                margin-left: 3px;
                padding: 2px 10px;
                line-height: 20px;
            }
        }
        &__device-name {
            text-transform: capitalize;
        }
        &__user-email {
            color: #000;
        }
        &__subTitle {
            margin: 0;
            font-size: 10px;
            line-height: 12px;
            color: #a6adb3;
            &__ellipseText {
                width: 98%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
.protocol-card-beta-height {
    height: 160px;
}
