@import '../../../styles/base.scss';

.property-card {
    &__container {
        // redundant but will allow for greater flexibility
        height: auto;
    }
    &__image {
        //Note Safari and mobile safari don't work with any relative height on map container :(
        @include card-block($height: 160px);
        @include media-radius(8px);
        background-color: $neutral-bg;

        // NOTE: fixes google map ignoring border-radius
        overflow: hidden;
    }

    &__content {
        display: inline-block;
        padding: $space-m;

        // Blocks are unaligned due to the first element (map having no text content)
        // can be removed if this layout moved to flex
        vertical-align: top;

        @include breakpoint(phone) {
            padding: 0;
        }
    }
    &__info {
        color: $text-secondary;
    }
}
