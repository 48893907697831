@import '../../../styles/base.scss';

.dpd-card {
    height: auto;
    &__block {
        @include card-block();
        @include media-radius(8px);
        background-color: $neutral-bg;
    }
    &__info {
        display: inline-block;
        vertical-align: top;
    }
}
