@import '../../../styles/base.scss';

.user-actions {
    &__container {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 34px;

        .beta-active {
            border-color: #FFCD4C;
        }
    }
    &__content {
        min-width: 120px;
        width: auto;
        
        .beta-active {
            background-color: #FFCD4C;
        }
    }
    &__name {
        @include text-ellipsis(100%, 100%);
        line-height: 19px;
    }
    &__role {
        color: #737373ad
    }
    &__chevron {
        color: #c4c4c4;
    }
}
