@import '../../../styles/base.scss';

.storage-progress {
    &__container {
        width: 100%;
    }
    &__note {
        color: $text-secondary;
        @include font--small();

        &--danger {
            color: $danger;
        }
    }
    &__info-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    &__available {
        @include font--small();
        @include font--strong();

        &--danger {
            color: $danger;
        }
    }
}
