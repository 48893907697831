@import '../../../styles/base.scss';

.subscribe-success {
    &__page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-color: $neutral-bg;
        padding-bottom: $space-xl;
        box-sizing: border-box;
    }
    &__title {
        @include font-title();
        text-align: center;
    }
    &__subtitle {
        text-align: center;
        color: $text-secondary;
        max-width: 560px;
    }
    &__illustration-container {
        height: 360px;
        width: 320px;
        position: relative;
        @include breakpoint(phone) {
            height: 200px;
        }
    }
    &__illustration {
        position: absolute;
        height: 450px;
        transform: translate(0, -80px);
        @include breakpoint(phone) {
            transform: translate(0, -150px) scale(0.6);
        }
    }
}
