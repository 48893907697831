@import '../../../styles/base.scss';

.update-billing {

    &__content {
        // display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
        border-radius: 0px 0px 8px 8px;
        background-color: white;
    }

    &__form {
        padding: 40px 24px 0px 24px;
        max-width: 435px;
    }

    &__action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.update-billing .modal-header__container {
    padding: 21px 16px;
}

.update-billing .form__line .update-billing__inline-field-1 {
    width: 747px !important;
    margin-right: 15px;
}