@import '../../../styles/base.scss';

.manager-card {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 5px;
        padding: $space-s;
    }
    &__picture {
        display: inline-block;
        width: 54px;
        height: 54px;
        background: #e6e8ea;
        border-radius: 3px;
        flex: 0 0 auto;
    }
    &__content {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
    }
    &__main {
        display: flex;
        overflow: hidden;

        @include breakpoint(phone) {
            margin-bottom: $space-s;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        justify-content: flex-end;
        margin-right: 0;

        @include breakpoint(desktop) {
            display: inline-block;
            margin-right: $space-s;
            flex: 0 0 auto;
        }
    }
    &__email {
        @include text-ellipsis(100%);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    &__role {
        @include font--secondary();
    }
    &__pending {
        @include font--secondary();
        margin-left: 10px;

        @include breakpoint(phone) {
            margin-bottom: $space-s;
            margin-right: auto;
            margin-top: 5px;
        }
    }
}

.manager-card__pending .std-chip {
    pointer-events: none;
    padding: 6px 14px;
}