@import '../../../styles/base.scss';

.invoice-card {
    &__container {
        border-radius: 4px;
        background-color: $info-bg;
        padding: $space-m;
        width: 180px;
        height: 125px;
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        color: $info;
    }
    &__containers-container {
        @include font--strong();
    }
    &__counter {
        @include font-title();
    }
    &__title {
        @include font--strong();
        display: flex;
        justify-content: space-between;
    }
    &__subtitle {
        color: $info;
    }
}
