@import '../../../styles/base.scss';

.modal-header {
    &__container {
        display: flex;
        box-sizing: border-box;
        padding: $space-squish-insert-m;
        background: #ffffff;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 1;
        border-bottom: $std-border;

        & > * {
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
                justify-content: flex-start;
            }
            &:last-child {
                justify-content: flex-end;
            }
        }
    }
    &__Content {
        padding: 18px 24px;
        border-bottom:  1px solid rgba(2, 20, 39, 0.3);
         span{
             font-weight: 500;
         }
    }
    &__title {
        text-align: center;
        flex: 1 1 100%;
    }
}