@import '../../../styles/base.scss';

.std-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &--contain {
        object-fit: contain;
    }
    &--empty {
        display: flex;
        justify-content: center;
        align-items: center;
        color: grey;
        text-align: center;
    }
    &--error {
        background: $neutral-bg;
    }
    &--full-width {
        height: auto;
        object-fit: contain;
    }
}
