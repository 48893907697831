@import '../../../styles/base.scss';

.shared-link {
    &__container {
        @include std-section();
        background-color: $neutral-bg;
        border-radius: 4px;
    }
    &__link-section {
        width: 100%;
        padding: $space-s;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
    }
    &__content {
        @include text-link(black);
        text-decoration: underline;
    }
}
