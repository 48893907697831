.vertical-progress {
    position: relative;
    width: 100%;
    height: 100%;

    &__bar {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0%;
        width: 100%;
        background: lightblue;
    }
    &__text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
