@import '../../../styles/base.scss';

.logo-uploader {
    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include breakpoint(phone) {
            flex-direction: column;
            justify-content: stretch;
        }
    }

    &__logo {
        position: relative;
        box-sizing: border-box;
        width: 112px;
        height: 117px;
        background-color: #f6f6f6;
        border-radius: 4px;
        border: $std-border;
        padding: $space-s;
        flex: 0 0 auto;
        margin-right: 30px;

        @include breakpoint(phone-small) {
            margin-bottom: 15px;
            margin-right: 0px;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--error {
            background: #fce6e3;
        }
    }

    &__content {
        display: flex;

        @include breakpoint(phone-small) {
            flex-wrap: wrap;
            justify-content: center;
        }

        &--error {
            color: $danger;
        }
    }

    &__text {
        max-width: 220px;
        font-size: 14px;
        @include breakpoint(phone-small) {
            max-width: 100%;
            text-align: center;
        }
    }

    &__action {
        position: relative;
        min-height: 40px;

        @include breakpoint(phone-small) {
            margin-top: 20px;
        }
    }

    &_requiredSection{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 110px;

        @include breakpoint(phone-small) {
            min-height: 0px;
            align-items: center;
        }
    }
}