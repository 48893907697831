@import '../../../styles/base.scss';

.feature-table-cell {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-m $space-m $space-m 0;
        box-sizing: border-box;
        border-bottom: 2px solid $border-color;
        text-overflow: ellipsis;
        flex: 1 1 25%;

        @include breakpoint(phone) {
            flex: 1 1 50%;
        }

        &--last {
            border-bottom: none;
            border-radius: 0 0 8px 8px;
        }
        &--highlighted {
            border-bottom: 2px solid white;
            padding: $space-m;
            background: white;
            text-align: center;
            position: relative;
            margin-bottom: -10px;
            &::before {
                content: ' ';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: 8px;
                box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
                    0px 8px 16px -4px rgba(0, 0, 0, 0.2);
                z-index: -1;
            }
        }
    }
    &__title {
        @include font--strong();
        @include font--small();
    }
    &__subtitle {
        @include font--small();
        color: $text-secondary;
    }
}
