.file-picker {
    &__trigger {
        position: relative;
    }
    &__anchor {
        display: inline-block;
    }
    &__add {
        width: 100px;
        height: 100px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position:relative;
        cursor: pointer;
    }
    &__add-icon {
        width: 20px;
        height: 20px;
    }
    &__add-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.004em;
        text-transform: uppercase;
        color: #000000;
        padding: 10px 0 0 0;
        margin: 0;
    }
    &__bg-transparent{
        background-color: transparent;
    }
}
