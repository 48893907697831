@import './base.scss';

// Basic highlight animation
@mixin std-pulse(
    $from-color: white,
    $to-color: $neutral-bg,
    $postfix: 'white'
) {
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-name: std-pulse-#{postfix};

    @keyframes std-pulse-#{postfix} {
        0%,
        100% {
            background-color: $from-color;
        }
        50% {
            background-color: $neutral-bg;
        }
    }
}

.std-pulse-white {
    @include std-pulse;
}
