@import '../../../styles/base.scss';

.sharing-intro {
    &__page {
        min-height: 100vh;
        background-color: $neutral-bg;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    &__content {
        max-width: 480px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: $space-xl;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @include breakpoint(phone) {
            width: 100%;
        }
    }
    &__title {
        @include font-title();
        text-align: center;
    }
    &__illustration {
        width: auto;
        height: 250px;

        @include breakpoint(phone) {
            width: 100%;
            height: auto;
        }
    }
    &__header {
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: $space-xl 0;
    }
}
