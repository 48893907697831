@import '../../../styles/base.scss';

.inovice {
    &__subtotal-container {
        @include font--strong();
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    &__total {
        @include font-header();
        @include font--strong();
    }
    &__next-invoice-note {
        @include font--small();
        color: $text-secondary;
    }
    &__container {
        padding: $space-m 0;
    }
}

.invoice-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__current {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #1dabd1;
        white-space: nowrap;
    }
    &__pickers {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
    &__month {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #061426;
        white-space: nowrap;
    }
    &__pickers--disable {
        color: #d1d1d1;
    }
}
