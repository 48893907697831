@import '../../../styles/base.scss';

.collapsible-list-alt {
    &__container {
        width: 100%;
        overflow: hidden;
    }
    &__title {
        display: flex;
        justify-content: space-between;
        color: $grey70;
        cursor: pointer;
    }
}
