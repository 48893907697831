@import '../../styles/base.scss';

.profile {
    &__page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
    }

    &__section {
        padding: $space-l;

        @include breakpoint(phone) {
            padding: $space-m;
        }

        .detail-hidden {
            pointer-events: none;

            div.std-icon {
                display: none;
            }
        }
    }

    &__setting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include breakpoint(phone) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__wrapper {
        padding: 10px 15px;
        background-color: $neutral-bg;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    &__content {
        border-radius: 4px;
        border: $std-border;
        background: white;
        flex: 1 1 100%;
    }

    &__header {
        padding: $space-xl $space-ll $space-l $space-ll;
    }

    &__space {
        margin-bottom: 20px;
    }

    &__tabs {
        margin-left: 20px;
    }

    &__sectionDevision {
        display: flex !important;
        justify-content: space-between !important;

        @include breakpoint(phone) {
            display: block !important;
        }
    }

    &__form {
        width: 50%;

        @include breakpoint(phone) {
            width: 100% !important;
        }
    }

    &__logoUploader {
        width: 40%;
        box-sizing: border-box;

        @include breakpoint(phone) {
            width: 100% !important;
        }

        &__titleWrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .profile {

            &__form {
                margin-top: 32px;
                width: 100%;

                .stack-ll {
                    margin-bottom: 10px;
                }
            }

            &__space {
                margin-bottom: 30px;
            }
        }

        .logoUploader-view {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            .profile {
                &__accountTitle {
                    margin: 0;
                }
            }

            .logo-uploader {
                &__text {
                    display: none;
                }
            }
        }

        .mobile {
            flex-direction: column;

            .logo-uploader {
                &__text {
                    display: block;
                }
            }
        }
    }

    &__enableBtn{
        display: flex !important;
        width: 100%;
        justify-content: space-between !important;
    }

    &__btnWrap {
        display: flex;
        justify-content: flex-end;

        button {
            padding: 6px 20px;
            font-weight: 500;
            min-height: 40px;
            @include breakpoint(phone-small) {
                padding: 6px 13px !important;
            }
        }

        &__editBtn {
            margin-left: auto;
        }
    }

    &__accountTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }

    &__onwerName {
        color: #737373ad;
    }

    &__changePasswordBtn {
        @include breakpoint(phone) {
            width: 100% !important;
            min-height: 40px !important
        }
        @include breakpoint(phone) {
            padding: 10px !important;
        }
    }
    &__title{
        font-weight: 500;
    }
    &__subTitle{
        color: #A6ADB3;
    }

    &__divider{
        border-color: #E8E8E8 !important;
    }

    &__editOptionBtn{
        min-width: 103px;
    }
}
