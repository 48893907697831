@import '../../../styles/base.scss';

.set-password-success-modal {
    &__header {
        padding: 25px 20px;
        background-color: white;
        border-bottom: $std-border;
        box-sizing: border-box;
    }
    &__content {
        box-sizing: border-box;
        padding: 25px 20px;
        height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
    }
    &__title {
        @include font-header();
        @include font--strong();
        margin-bottom: 12px;
    }
    &__text {
        @include font--small();
        @include font--secondary();
        margin-bottom: 40px;
    }
    &__graphic {
        width: 97px;
        height: 97px;
        margin-top: 40px;
    }
}
