@import '../../../styles/base.scss';

.issue-card {
    width: 354px;
    padding: $space-m;
    box-sizing: border-box;
    white-space: nowrap;

    &__image {
        width: 96px;
        height: 96px;
        position: relative;
        display: inline-block;
        background-color: #f6f6f6;
        @include media-radius(4px);
        flex: 0 0 auto;
    }
    &__main {
        display: flex;
    }
    &__title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $accent;
        &--danger {
            color: $danger;
        }
        &--closed {
            color: $success;
        }
    }
    &__content {
        overflow: hidden;
    }

    &--outlined {
        border-radius: 4px;
        border: 1px solid $border-inactive;
    }
    &--closed {
        background-color: $success-bg;
        border: 1px solid $success;
        border-radius: 4px;
    }
}
