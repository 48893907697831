@import '../../../styles/base.scss';

.std-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.55);
    border-radius: 100%;
    flex: 0 0 auto;
    vertical-align: middle;
    &--warn {
        background: $accent;
    }
    &--danger {
        background: $danger;
    }
}
