@import '../../../styles/base.scss';

.std-tab {
    @include std-tab();
    border-bottom: 2px solid #000000;
    color: $text-primary;

    &--inactive {
        @include font--small();
        color: $grey70;
        border-bottom: 2px solid transparent;
    }
    &--disabled {
        @include font--small();
        color: $grey55;
        border-bottom: 2px solid transparent;
        cursor: initial;
    }
}
