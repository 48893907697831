@import '../../../styles/base.scss';

.issue-view {
    border: $std-border;
    border-radius: 8px;
    max-width: 400px;
    background: white;
    &__header {
        height: 56px;
        box-sizing: border-box;
        padding: $space-m;
    }
}
