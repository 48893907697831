@mixin breakpoint($point) {
    @if $point == desktoplg {
        @media (max-width: 1200px) {
            @content;
        }
    } @else if $point == tablet {
        @media (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    } @else if $point == phone {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point == tablet-max {
        @media (max-width: 1024px) {
            @content;
        }
    } 
    @else if $point == desktop {
        @media (min-width: 1025px) {
            @content;
        }
    } @else if (phone-small) {
        @media (max-width: 374px) {
            @content;
        }
    }
}

@mixin platform($platform) {
    @if $platform == iOS {
        @supports (-webkit-touch-callout: none) {
            @content;
        }
    }
}

@mixin text-crop(
    $line-height: 1.3,
    $top-adjustment: 0px,
    $bottom-adjustment: 0px
) {
    // Configured in Step 1
    $top-crop: 9;
    $bottom-crop: 8;
    $crop-font-size: 36;
    $crop-line-height: 1.2;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: max(
            (
                $top-crop + ($line-height - $crop-line-height) *
                    ($crop-font-size / 2)
            ),
            0
        ) / $crop-font-size;
    $dynamic-bottom-crop: max(
            (
                $bottom-crop + ($line-height - $crop-line-height) *
                    ($crop-font-size / 2)
            ),
            0
        ) / $crop-font-size;

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}

@mixin text-link($text-color: $text-primary) {
    color: $text-color;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:active {
        text-decoration: none;
        color: $text-color;
    }
    &:hover {
        text-decoration: none;
        color: $text-color;
    }
    &:focus {
        text-decoration: none;
        color: $text-color;
        outline: none;
    }
    &:visited {
        text-decoration: none;
        color: $text-color;
    }
}

@mixin text-link--color($text-color) {
    color: $text-color;
    &:active {
        color: $text-color;
    }
    &:hover {
        color: $text-color;
    }
    &:focus {
        color: $text-color;
    }
    &:visited {
        color: $text-color;
    }
}

@mixin absolute-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
}

@mixin css-ripple($hover-tint: #eee, $ripple-tint: #c2c2c2) {
    background-position: center;
    transition: background 60ms;
    &:hover {
        background: $hover-tint
            radial-gradient(circle, transparent 1%, $hover-tint 1%)
            center/15000%;
    }
    &:active {
        background-color: $ripple-tint;
        background-size: 100%;
        transition: background 0s;
    }
}

@mixin text-ellipsis($width: auto, $max-width: auto) {
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: $max-width;
    width: $width;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-align {
    display: flex;
    align-items: center;
}

@mixin flex-justify {
    display: flex;
    justify-content: center;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin media-radius($radius) {
    border-radius: $radius;
    .std-picture {
        border-radius: $radius;
    }
    .std-video__content {
        // TODO: remove duplicate video classes?
        border-radius: $radius;
    }
}
