@import '../../../styles/base.scss';

// TODO: refactor the component styles
.gallery {
    overflow: hidden;
    &--inline {
        display: inline-block;
        .gallery__item {
            margin-bottom: 0;
        }
    }
    &--disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    &__container {
        &--centered {
            text-align: center;
        }
    }
    &__title {
        @include font--secondary();
        text-align: center;
    }
    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 $space-s $space-s $space-s;
    }
    &__button {
        position: relative;
        min-width: 100%;
    }
    &__media {
        position: relative;
        width: 96px;
        height: 96px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 4px;
        overflow: hidden;
        &--large {
            width: 128px;
            height: 128px;
        }
        &--empty {
            background: #ffffff;
        }
        &--dark {
            background-color: $neutral-bg;
        }
    }
    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__play-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0.7;
        color: white;
    }
    &__info-text {
        display: inline-block;
        vertical-align: top;
        width: 260px;
    }
    &__action {
        width: 25px;
        height: 25px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
    &__trash-icon {
        color: #ffffff;
        width: 13px;
        height: 15px;
    }
}
