@import '../../../styles/base.scss';

.object-card {
    &__container {
        display: flex;
        border-radius: 8px;
        padding: $space-s;
        overflow: hidden;
        @include breakpoint(phone) {
            display: block;
        }
        height: auto;

        &--dragging {
            background-color: white;
            border: 1px solid black;
        }
    }
    &__image {
        @include card-block($break-height: 128px);
        @include media-radius(8px);
        position: relative;
        background-color: $neutral-bg;
    }
    &__count {
        position: absolute;
        left: 0;
        top: 0;
        padding: $space-squish-insert-s;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 4px 0 4px 0;
    }
    &__content {
        display: inline-block;
        vertical-align: top;
    }
    &__rooms {
        max-width: 625px;
        display: flex;
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: hidden;
        .std-link {
            display: block;
            text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
            width: 120px;
            @include breakpoint(phone) {
                width: 100px;
            }
        }
    }
}
