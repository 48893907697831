@import '../../../styles/base.scss';

.trial-plan {
    &__container {
        display: flex;
        flex-direction: column;
        padding-top: $space-l;

        &--highlighted {
            min-width: 184px;
            text-align: center;
            background: white;
            border-radius: 8px 8px 0 0;
            padding: $space-l;
            box-sizing: border-box;
        }
    }
    &__header-container {
        height: 150px;
        padding-right: $space-m;
        box-sizing: border-box;
        flex: 0 0 auto;

        &--highlighted {
            padding-right: 0;
        }
    }
    &__title {
        @include font-header();
        @include font--strong();
    }
    &__price {
        @include font-header();
    }
    &__duration {
        @include font--small();
        color: $text-secondary;
    }
    &__wrapper {
        flex: 1 1 25%;

        @include breakpoint(phone) {
            flex: 1 1 50%;
        }
        &--highlighted {
            position: relative;
            margin-bottom: -10px;
            &::before {
                content: ' ';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: 8px;
                box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
                    0px 8px 16px -4px rgba(0, 0, 0, 0.2);
                z-index: -1;
            }
        }
    }
}
