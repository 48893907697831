@import '../../../styles/base.scss';

.profile-team-tab {
    &__main {
        padding: $space-l;

        @include breakpoint(phone) {
            padding: $space-m;
        }
    }
    &__action {
        padding: $space-s $space-l;

        @include breakpoint(phone) {
            padding: $space-s $space-m;
        }
    }
}
