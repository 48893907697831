@import '../../../styles/base.scss';

.extra-feature {
    &__container {
        @include font--small();
        padding: $space-squish-insert-m;

        &--inline {
            display: flex;
        }
    }
    &__highlight {
        @include font--small();
        @include font--strong();
    }
}
