@import '../../../styles/base.scss';

.std-block {
    @include std-block();
    background-color: $neutral-bg;
    border: 1px solid #222222;

    &--inactive {
        border: $std-border;
    }
    &--black {
        color: white;
        background: black;
    }
    &--borderless {
        border: none;
    }
}
