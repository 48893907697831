@import '../../../styles/base.scss';

.selected-plan {
    &__title {
        @include font-title();
        font-weight: 500;
    }
    &__section__item {
        padding-bottom: $space-m;
    }
    &__subtitle {
        @include font-header();
    }
    &__duration {
        @include font--small();
        color: $text-secondary;
    }
    &__section__title {
        @include font--small();
        color: $text-secondary;
        padding: $space-squish-insert-m;
    }
    &__header {
        padding: $space-ll $space-m $space-xll $space-m;
    }
    &__container {
        min-width: 250px;
    }
}
