@import '../../../styles/base.scss';

.collapsible-list {
    &__content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--wide {
            justify-content: space-between;
        }
    }
    &__title {
        @include font--small();
        @include font--secondary();
        cursor: pointer;
        margin-right: $space-m;
        @include breakpoint(phone) {
            margin-right: 0;
        }
    }
    &__count {
        display: inline-flex;
        flex-wrap: nowrap;
    }
}
