.custom-icon {
    &__container {
        width: 14px;
        height: 14px;
        display: inline-block;
        flex: 0 0 auto;

        &--cross {
            width: 12px;
            height: 12px;
            padding: 8px;
            border-radius: 100%;
        }
        &--back-arrow {
            width: 17px;
            height: 14px;
            padding: 6px 4.5px;
            vertical-align: middle;
        }
    }
    &__icon {
        vertical-align: top;
        width: 100%;
        height: 100%;
    }
}
