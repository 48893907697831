.user-offline-notification-modal-content {
    max-width: 450px;
    width: 450px;

    .user-offline-notification-modal {
        padding: 29px 36px;
        .modal-info {
            text-align: center;
            &__1 {
                margin-bottom: 10px;
                font-weight: 500;
                text-transform: capitalize;
            }
            &__2 {
                margin-bottom: 26px;
            }
            &__3 {
                margin-bottom: 10px;
            }
        }
    }
}

.user-offline-notification-modal-actions {
    flex-direction: row;
    justify-content: space-between;
    padding: 29px 60px;
}

.user-offline-notification {
    &__discard {
        margin-bottom: 10px;
    }
    &__retry {
        margin-bottom: 10px;
    }
    &__go-offline {
        margin-bottom: 10px;
    }
}
