@import '../../../styles/base.scss';

.select-list-accordion {
    &__container {
        border: $std-border;
        border-radius: 6px;
    }
    &__content {
        padding: $space-stretch-insert-l;
    }
}
