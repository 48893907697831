@import '../../../styles/base.scss';

.support {
    &__button {
        cursor: pointer;
        @include flex-center();
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: $std-border;
        background: white;
    }
    &__container {
        z-index: 3;
        position: fixed;
        right: $space-l;
        bottom: $space-l;
        @include breakpoint(phone) {
            right: $space-m;
            bottom: $space-m;
        }
    }
}
