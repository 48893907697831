@import '../../../styles/base.scss';

.trial-billing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    border: $std-border;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55),
        0px 8px 16px -4px rgba(0, 0, 0, 0.2);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
    }

    &__header-text {
        @include font-header();
        text-align: center;
    }

    &__page {
        display: flex;
        flex-direction: column;
        background: white;
        min-height: 100vh;
        background-color: $neutral-bg;
    }

    &__section {
        padding: $space-xl 0;
        margin: auto;
    }

    &__form {
        border-left: $std-border;
        box-sizing: border-box;
        text-align: left;
        padding: $space-stretch-insert-xl;
        max-width: 435px;
        width: 100%;

        &--no_plan {
            border-left: none;
        }
    }
}
