.std-checkbox {
    &__text {
        cursor: pointer;
        color: #000;
        font-weight: 500;
    }
    &__element {
        z-index: 1;
        flex: 0 0 auto;
    }
    &__container {
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        word-break: break-word;
        flex: 0 0 auto;

        &--wrap {
            word-break: break-word;
            flex: initial;
        }
    }
}
