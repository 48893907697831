.std-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.std-mt {
    margin-top: 63px;
}

.std-mt-25 {
    margin-top: 25px;
}
.std-mt-5 {
    margin-top: 5px;
}

.std-pt-25 {
    padding-top: 25px;
}

.std-pt {
    padding-top: 63px;
}

.std-mb {
    margin-top: 63px;
}

.std-space-nowrap {
    white-space: nowrap;
}
