@import '../../../styles/base.scss';

.std-note {
    display: flex;
    background-color: rgba(233, 233, 233, 0.4);
    border-radius: 6px;
    padding: $space-m;
    align-items: center;
    justify-content: space-between;

    &--centered {
        align-items: center;
    }
    &--success {
        color: $success;
        background-color: $success-bg;
    }
    &--info {
        color: $info;
        background-color: $info-bg;
    }
    &--slim {
        padding: $space-s;
    }
    &--danger {
        color: $danger;
        background-color: $danger-bg;
    }

    &-enter {
        opacity: 0;
        &-active {
            opacity: 1;
            transition: opacity 500ms ease-in;
        }
    }
    &-exit {
        opacity: 1;
        &-active {
            opacity: 0;
            transition: opacity 500ms ease-in-out;
        }
    }
}
