@import '../../../styles/base.scss';

.property-accordion {
    &__header {
        display: flex;
        justify-content: space-between;
    }
    &__title {
        @include font--small();
        @include font--secondary();
    }
    &__arrow {
        &--open {
            transform: rotate(-90deg);
            transition: transform 500ms linear;
        }
    }
    &__new {
        padding: $space-stretch-insert-l;
    }
}
