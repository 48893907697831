@import '../../../styles/base.scss';

.std-attachment {
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    box-sizing: border-box;
    border-radius: 4px;
    padding: $space-s;
    border: $std-border;

    &--borderless {
        border: none;
    }
}
