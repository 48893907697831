@import '../../../styles/base.scss';

.issue-progress {
    &__container {
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid $border-inactive;
    }
    &__section {
        padding: $space-m;
    }
    &__header {
        text-align: center;
        padding: $space-s;
    }
    &__logs {
        //
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 350px;
    }
}
